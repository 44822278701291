import React, { Component, useState, useEffect } from "react";
//import { useSelector } from "react-redux";
// import {
//   selectCurrentUser,
//   selectCurrentToken,
// } from "../features/auth/authSlice";
import { Link, useNavigate } from "react-router-dom";
import homeBtn from "../assets/img/btn_home_Off3.svg";
import profileBtn from "../assets/img/btn_profile.svg";
import logoutBtn from "../assets/img/btn_LogOut_Off3.svg";
//import { connect } from "react-redux";
//import * as actions from "../actions";
//import { Redir } from 'react-router-dom';
import { saveEmotion } from "../utils/usersAPI";
import FooterWithLinks from "./FooterWithLinks";
import EmotionWidget from "./emotion/EmotionWidget";
import EmotionMessage from "./emotion/EmotionMessage";
import "../css/welcome.css";
import { getMessage } from "./emotion/getMessage";
import WelcomeMenu from "./WelcomeMenu";
import EnergyWidget from "./energy/EnergyWidget";

const Welcome = () => {
  //const token = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [username, setUsername] = useState(window.localStorage.getItem("username"));
  const [displayEmotion, setDisplayEmotion] = useState(false);
  const [displayEmotionMessage, setDisplayEmotionMessage] = useState(false);
  const [emotion, setEmotion] = useState({});
  const [displayEnergy, setDisplayEnergy] = useState(false);

  //console.log("welcome token: ", JSON.stringify(token));
  //const welcome = token ? `Welcome ${user}` : "Welcome!";
  //const tokenAbbr = `${token.slice(0, 9)}...`;

  useEffect(() => {
    setDisplayEmotion(getRecentEmotion());
    
  }, []);

  const getRecentEmotion = () => {
    const lastEmotion = window.localStorage.getItem("emotion") || null;
    const lastEmotionDate = window.localStorage.getItem("emotionDate") || null;

    if (lastEmotion && lastEmotionDate) {
      const currentDate = new Date();
      const emotionDate = new Date(lastEmotionDate);
      // console.log("emotion dates: ", currentDate, emotionDate, currentDate - emotionDate);
      return (currentDate - emotionDate) > (7200 * 1000) || false; // Set to 2 hours
    } else {
      return true;
    }
  }

  const getRecentEnergy = () => {
    const lastEnergy = window.localStorage.getItem("speed") || null;
    const lastEnergyDate = window.localStorage.getItem("energyDate") || null;

    if (lastEnergy && lastEnergyDate) {
      const currentDate = new Date();
      const energyDate = new Date(lastEnergyDate);
      // console.log("energy dates: ", currentDate, energyDate, currentDate - energyDate);
      return (currentDate - energyDate) > (7200 * 1000) || false; // Set to 2 hours
    } else {
      return true;
    }

  }

  const saveEmotionLocalStorage = (emotionId) => {
    const currentDate = new Date();
    window.localStorage.setItem("emotion", emotionId);
    window.localStorage.setItem("emotionDate", currentDate);
  }

  const saveEnergyLocalStorage = (speed, time) => {
    const currentDate = new Date();
    //window.localStorage.setItem("speed", speed);
    //window.localStorage.setItem("time", time);
    window.localStorage.setItem("energyDate", currentDate);
  }

  const handleEmotion = (emotionId) => {
    saveEmotionLocalStorage(emotionId);
    setDisplayEmotion(false);
    setEmotion(getMessage(emotionId));
    // TODO: Persist Emotion to state and / or database - wait until energy to update database?
    setDisplayEmotionMessage(true);
  };

  const handleEmotionMessageClick = async () => {
    setDisplayEmotionMessage(false);
    if (getRecentEnergy()) {
      setDisplayEnergy(getRecentEnergy());
    } else {
      const result = await saveEmotion({
        username: username,
        emotion: emotion?.emotion,
      });
      // console.log("Emotion only saving result is: ", result, username, emotion.emotion);
    }
   
  };

  const handleEnergyClick = async (energy, duration) => {
    const result = await saveEmotion({
      username: username,
      emotion: emotion?.emotion,
      energy: energy,
      duration: duration,
    });
    // console.log("Emotion & energy saving result is: ", result, username, emotion.emotion, energy, duration);
    saveEnergyLocalStorage(energy, duration);
    setDisplayEnergy(false);
  };

  const handleGoToRoom = (room) => {
    navigate(`/video/?room=${room}`, { replace: true });
  };

  const handleLogout = (e) => {
    window.localStorage.clear();
    //this.setState({ redirect: "/" });
    
    // window.localStorage.removeItem("token");
    // window.localStorage.setItem("authenticated", false);
    // window.localStorage.removeItem("emotion");
    // window.localStorage.removeItem("emotionDate");
  };

  const getToken = () => {
    const tokenString = window.localStorage.getItem("token");
    return tokenString;
  };

  const getAuth = () => {
    const stat = window.localStorage.getItem("authenticated");
    return stat;
  };

  return (
    <>
    
       {/* Conditionally display overlay when any of the widgets are active */}
    {(displayEmotion || displayEmotionMessage || displayEnergy) ? (
      <div className="welcome-overlay"></div>
    ) : null}
  
    {displayEmotion ? (
      <EmotionWidget handleEmotion={handleEmotion} />
    ) : null}
    {displayEmotionMessage ? (
      <EmotionMessage
        heading={emotion.heading}
        message={emotion.message}
        handleEmotionMessageClick={handleEmotionMessageClick}
      />
    ) : null}
    {displayEnergy ? (
      <EnergyWidget handleEnergyClick={handleEnergyClick} />
    ) : null}
    
      <div id="welcome">
        <div className="nav-btn-div">
          <Link className="link" to="/welcome">
            <img src={homeBtn} />
          </Link>
          <Link to="/profile">
            <img id="profileBtn" src={profileBtn} alt="" />
          </Link>
          <Link className="link" to="/" style={{marginRight: '60px'}}>
            <img src={logoutBtn} alt="" onClick={handleLogout} />
          </Link>
        </div>

        <div id="welcome-div-top">
          <div className="logo-img-wrapper">
            <img
              id="logo-img"
              className="logo"
              src={require("../assets/img/d4h_logo.png")}
              alt={"Dance for Healing Logo"}
            />
          </div>
        </div>

        <div id="welcome-div-bottom">
          {/* <div>{token}</div> */}
          <div className="row">
            <WelcomeMenu username={username} handleGoToRoom={handleGoToRoom} />
          </div>
        </div>

        <div id="welcome-div-footer">
          <FooterWithLinks />
        </div>
      </div>
    
    </>
  );
};

export default Welcome;
