import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
i18n
.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
.init({
    debug: true,
    fallbackLng: 'en',
    backend:{
        // todo: may not need this setting
        requestOptions: {
            cache: 'no-store',
          },        
    }
});


// i18n.changeLanguage('cn');
export default i18n;