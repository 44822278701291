import React, {
   Component 
  } from 'react';
import { 
  Card, 
  Container, 
  Row, 
  Col, 
  ListGroup
} from "react-bootstrap";
import { AccordionHelpQs } from './HelpComponents'
import i18n from "../../i18n";
import { Translation } from 'react-i18next';
import { apiUrl } from "../../utils/utils";
import "../../assets/css/help.scoped.css";

const api = apiUrl();

/**
 * 
 * Display help documentation from JSON record
 *  Purpose is to display help content edited in and sourced from a G Doc
 * HelpFAQPage
 */
export class HelpFAQPage extends Component {
  constructor(props) {
      super(props)
      this.state = {
          Questions: [{Question:'qustionsss', Answer:"AAAAA"},{Question:'qERTustionsss', Answer:"ABBAAAA"},{Question:'qzxcustionsss', Answer:"zxcAAAAA"},{Question:'bvnqustionsss', Answer:"vbnAAAAA"}],
          open:false,
      }
      this.getHelpQuestions = this.getHelpQuestions.bind(this);
      this.mainFAQHelpContent = this.mainFAQHelpContent.bind(this);
  }

  componentDidMount() {
    const language = i18n.language;
    this.getHelpQuestions({language});
  }

  // todo: move method to an API file
  async getHelpQuestions({ language = 'en' } = {}) {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'ngrok-skip-browser-warning': 'true' 
    };
    // todo: Update backend to be able to use code 'cn' directly. Since Google Translate uses codes zh-CN an zh-TW for Chinese.
    if(language=='en-US' || language=='en-GB' ||  /^en/i.test(language)){
      language = 'en';
    } else if(language=='cn'||/^cn/i.test(language) ||/^zh/i.test(language)){
      language = 'zh';
    } else if(/^es/i.test(language)){
      language = 'es';
    }
    let langUrl = language=='en'?`${api}/admin/helpdoc` : `${api}/admin/helpdoc/${language}`
    console.log({langUrl});
    const _questions = await fetch(langUrl,
      {
        method: 'GET',
        headers: headers
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          console.debug('error response:', response)
        }
      })
      .catch(err => console.error('error:::', err));
    this.setState({ AllQuestions: _questions, Questions: _questions.helpDoc[0].Questions });
  }

  mainFAQHelpContent() {
    return (
        <Row style={{}}>
          <Col lg={1} ></Col>
          <Col lg={10} md={12} className="mb-4 mb-lg-4" >

            {this.state?.AllQuestions?.helpDoc?.map((a,i)=>{
              const {Topic, Questions} = a;
              return (
              <>
              <h1 style={{fontSize:'40px', marginTop:'1.3em', marginBottom:'0.2em'}}>{Topic}</h1>
              <Row>
                <AccordionHelpQs helpqs={Questions} />
              </Row>
              </>)
            })}
          <Row style={{height:"4em", marginTop:'2em', marginBottom:'2em'}}></Row>
          </Col>
          <Col lg={1} ></Col>
        </Row>
      );
    }    
  render() {
      return  (
          <>
          <style>
              {`
              body {
                  background-color: white;
              }
              `}
          </style>
          <div className="d-flex flex-column min-vh-100" style={{backgroundColor: 'white', color: "var(--gray-text)" }}>
            <nav className="navbar sticky-top " style={{color: "var(--gray-text)", backgroundColor: '#ea7488', border:'0px', borderRadius: '0px' }}>
            <a className="navbar-brand"  href="/">Dance4healing</a>              
               </nav>
            <main className="flex-grow-1">
              <Container className="my-4">
                <h1 className="mb-4 text-center" style={{fontSize:"44px"}}>
                  <Translation> 
                    {
                      t => t("helpsupport.help_faq_title")
                    }
                  </Translation>
                  </h1>
                  {/* Getting started with Dance4healing</h1> */}
                <this.mainFAQHelpContent />
              </Container>
            </main>
            {/* <Footer /> */}
          </div>
          </>
        );
  }
}

  

function Footer() {
  return (
    <footer className="bg-light py-3 mt-auto">
      <Container>
        <p className="text-center mb-0">&copy; 2024 D4H</p>
      </Container>
    </footer>
  );
}
