import React, { useReducer, useEffect, useMemo, useState } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useTranslation } from 'react-i18next';


const useTOURSTEPS=() =>{ 
  const { t, i18n } = useTranslation();
  const osteps = useMemo(()=> {
    const steps = [
    {
      target: ".tour-register",
      content:t("home.tour.register"),// t("helpsupport.menu_documentation"),//
        //content:"You can register an account with an email and password.",
        disableBeacon: true,

    },
    {
      target: ".tour-account-password",
      content: t("home.tour.password"),//t('password'),
    },
    {
      target: ".tour-login-options-fb",
      content: t("home.tour.facebook"),
        // "You can also directly login with your Facebook account",
    },
    {
      target: ".tour-login-options-google",
      content: t("home.tour.google"),
      // "You can also directly login with your Google account",
    },
    {
      target: ".tour-login-options-linkedin",
      content: t("home.tour.linkedin"),
      // "You can also directly login with your LinkedIn account",
      skipBeacon: false,
    }
  ];
  return {steps};
  }, [i18n.language, i18n, t])
  return osteps;
};



const INITIAL_STATE = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: {},// HOME_TOUR_STEPS,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "START":
      return { ...state, run: true };
    case "RESET":
      return { ...state, stepIndex: 0 };
    case "STOP":
      return { ...state, run: false };
    case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
    case "RESTART":
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
      case "RESET_LANG":
        return { ...state, stepIndex: 0, ...action.payload };
      default:
      return state;
  }
};

let tourStateX, dispatchX, startTourX;


const useTourStepsHome=()=>{
  const osteps = useTOURSTEPS();
  const {steps} = osteps;
  const [tourState, dispatch] = useReducer(reducer, {...INITIAL_STATE, steps});
  const startTour = () => {
    dispatch({ type: "RESTART" });
  };
  return {steps, tourState,dispatch, startTour};
}

const Tour = () => {
  const {i18n } = useTranslation();
  const {steps, tourState,dispatch, startTour} = useTourStepsHome();
  startTourX = startTour;
  useEffect(() => {
    const tourHome = localStorage.getItem("tourHome") || 0;
    if (!tourHome || tourHome<2) {
      localStorage.setItem("tourHome", (!tourHome) ? 1:2 );
      dispatch({ type: "START" });
    } else {
      //don't auto start tour
    }
  }, []);
  useEffect(()=>{
    dispatch({type:"RESET_LANG", 
      payload:{steps}
      })
  },[i18n.language, steps]);
  useEffect(()=>{
    Tour.startTour=startTour;
    startTourX=startTour;
  }, [Tour]);

  const callback = (data) => {
    const { action, index, type, status } = data;

    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      dispatch({ type: "STOP" });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };


  return (
    <>
      <JoyRide   
        {...tourState}
        callback={callback}
        showSkipButton={true}
        styles={{

          tooltipContainer: {
            textAlign: "left",
          },
          buttonNext: { 
            backgroundColor: '#cc3366', 
            color: 'white', 
            fontWeight: 'bold' 
          }, 
          buttonBack: {
            marginRight: 10,
          },
          options: { zindex: 1000}
        }}
        locale={{
          last: "End tour",
        }}
      />
    </>
  );
};

export { Tour, startTourX as startTour, tourStateX as tourState, dispatchX as dispatch};
