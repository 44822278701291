export const apiUrl = () => {
  const environment = process.env.REACT_APP_ENV || "production";
  // console.log('environment: ', environment);
  switch (environment) {
    case "local":
      return process.env.REACT_APP_API_URL_LOCAL;
    case "dev":
      return process.env.REACT_APP_API_URL_DEV;
    default:
      return process.env.REACT_APP_API_URL_PROD;
  }
};

export const apiBaseUrl = (path = 'api') => {
  if (!path) {
    path = ''
  } else if (path.indexOf('/') === 0) {
    path = path;
  } else {
    path = `/${path}`;
  }
  const environment = process.env.REACT_APP_ENV || "production";
  switch (environment) {
    case "local":
      return process.env.REACT_APP_API_URL_LOCAL + path;
    case "dev":
      return process.env.REACT_APP_API_URL_DEV + path;
    default:
      return process.env.REACT_APP_API_URL_PROD + path;
  }  // console.log('environment: ', environment);
};


export const removeCookie = (name) => {
  const date = new Date();
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);
  var expires = "; expires=" + date.toGMTString();

  document.cookie = name + "=" + "" + expires + "; path=/";
};

export const readCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const randomRoomName = (username) => {
  const randomNumber = Math.floor(Math.random() * 10000);
  const fname = localStorage.getItem("fname");
  return fname
    ? fname + "Rocks" + randomNumber
    : username || "dance" + randomNumber;
};

export const expandBackgroundStartImages = () => {
  const startImgs = document.querySelectorAll("#start-img");
  for (let i = 0; i < startImgs.length; i++) {
    startImgs[i].style.width = "185px";
  }
};
