import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import login_facebook from "../assets/img/btn_login_facebook.png"
import login_twitter from "../assets/img/btn_login_twitter.png"
import login_google from "../assets/img/btn_login_google.png"
import login_linkedin from "../assets/img/btn_login_linkedin.png"
import { apiUrl } from "../utils/utils";
import {HelperDocsMenuList } from './helpsupport/HelpMenus'
const api = apiUrl();

function Footer({children}) {
  const { t } = useTranslation();
  // const alt_text_FB = "Log in with your social account on Facebook"
  return (
    <footer>
      
      <p style={{ fontFamily: 'Roboto, sans-serif' }}>{t('footer.login')}</p>
      <div className="icons">
        <a href={`${api}/users/facebook`} >
          <img src={login_facebook} alt="" className='tour-login-options-fb' style={{marginLeft:"4.5em"}} />
        </a>
        {/* <a href={`/`}>
          <img src={login_twitter} alt="" />
        </a> */}
        <a href={`${api}/users/google`} >
          <img src={login_google} alt="" className='tour-login-options-google' />
        </a>
        {/* <img src={login_google} onClick={() => googleLogin()} alt="" /> */}
        <a href={`${api}/users/linkedin`} >
          <img src={login_linkedin} alt="LinkedIn login" className='tour-login-options-linkedin' />
        </a>
        <span style={{position:"relative",float:"right", marginTop:"2rem"}}>{  [<HelperDocsMenuList /> ][0]}
          </span>
      </div>
      <div className="buttonarea">
        <Link to="/login" className="btn btn-lg login tour-account-password" >{t("footer.Log In")}</Link>
        <Link to="/register" className="btn btn-lg register tour-register" style={{ width: '285px' }}>
          <span onClick={() => window.localStorage.clear()}>{t("footer.Sign Up")}</span>
        </Link>
      </div>

      <p style={{ fontSize: 14 }}>{t("footer.copyright")}</p>
      <Trans i18nKey="footer.usechrome" components={{ a: <a href='https://www.google.com/chrome/browser/desktop/' /> }} />
      <>{children}</>
    </footer>)
}

export default Footer;
