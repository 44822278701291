import { Table, Button, Card } from 'react-bootstrap';
import React, { useState, Children, cloneElement } from 'react';
import { useCollapse } from 'react-collapsed'
import { ReactComponent as TriangleSVG } from "@material-design-icons/svg/filled/chevron_right.svg";


export const HistoricalValuesTable = ({ data, onRevert }) => {
  console.debug({data})
  return (
    <Table striped bordered hover className='helpsupport googledochistory historicalhelpdoctable'>
      <thead>
        <tr>
          <th>Update Date</th>
          <th>Admin</th>
          <th>Google Doc version</th>
          <th>Set Live</th>
        </tr>
      </thead>
      <tbody> 
        { (Array.isArray(data)) ? (data.map((item, index) => 
          <tr key={item.googleVersionHash}>
            <td>{item.updateDate}</td>
            <td>{item.admin}</td>
            <td><a href={item.googleDocLink} target='none'>{item.googleVersionHash}</a></td>
            <td>
              <Button 
                variant="primary" 
                size="sm" 
                disabled={true}
                striped
                onClick={() => onRevert(item, index)}
              >
                Set Live
              </Button>
            </td>
          </tr>
        )) : 
        [
          <tr key={"item.googleVersionHash"}>
            <td>{"item.updateDate"}</td>
            <td>{"item.admin"}</td>
            <td><a style={{color:'var(--gray-text)'}} href={"item.googleDocLink"} target='none'>{"item.googleVersionHash"} : https://docs.google.com/document/d/1tW_ITAxF2ImfhYyCtEFh3Y8v8SmLSOWNDMZFyo4T4tE/edit </a></td>
            <td>
              <Button 
                variant="primary" 
                size="sm" 
                disabled={true}
                striped
                onClick={() => onRevert("item", "index")}
              >
                Set Live
              </Button>
            </td>
          </tr>,
                    <tr key={"item.googleVersionHash"}>
                    <td>{"item.updateDate"}</td>
                    <td>{"item.admin"}</td>
                    <td><a style={{color:'var(--gray-text)'}} href={"item.googleDocLink"} target='none'>{"item.googleVersionHash"} : https://docs.google.com/document/d/1tW_ITAxF2ImfhYyCtEFh3Y8v8SmLSOWNDMZFyo4T4tE/edit </a></td>
                    <td>
                      <Button 
                        variant="primary" 
                        size="sm" 
                        disabled={true}
                        striped
                        onClick={() => onRevert("item", "index")}
                      >
                        Set Live
                      </Button>
                    </td>
                  </tr>
        
        ].map(x=>x)}
      </tbody>
    </Table>
  );
};


export const HelpCards = ({ helpqs }) => {
  const [expanded, setExpanded] = useState(null);
  return (
    <>
      <style>
        {`

          .faq-card.faq-helpcards.card {
              position: relative;
              display: flex;
              flex-direction: column;
              min-width: 0;
              /*word-wrap: break-word;*/
              background-color: #fff;
              background-clip: border-box;
              border: 1px solid rgba(0,0,0,.125);
              border-radius: .25rem;
          
          }

        .card.faq-card.faq-helpcards div {
          font-size: 20px;

          font-weight: bold;
          line-height:unset;
          height:unset;
          width:unset;
          text-align: justify;


        }
        .card-header {
          cursor: pointer;
        }

        .fa-chevron-up,
        .fa-chevron-down {
          transition: transform 0.3s ease-in-out;
        }

        .fa-chevron-up {
          transform: rotate(180deg);
        }`}
      </style>
      {helpqs && helpqs.map((question, index) => (
        <Card key={index} className={["my-3", "faq-card", "faq-helpcards"]} style={{ border: 'none' }}>
          <Card.Header style={{ borderBottom: '2px solid lightpink', background: 'transparent' }} onClick={() => setExpanded(index === expanded ? null : index)}>
            <div className="d-flex justify-content-between align-items-center">
              <span>{question.Question}</span>
              {expanded === index ? (
                <i className="fas fa-chevron-up"></i>
              ) : (
                <i className="fas fa-chevron-down"></i>
              )}
            </div>
          </Card.Header>
          {expanded === index && (
            <Card.Body>
              <Card.Text>{question.Answer}</Card.Text>
            </Card.Body>
          )}
        </Card>
      ))}
    </>
  );
};


const makeReconciliationKey=(m="")=>{
  if(m=="") return "";
  if(typeof m != 'string') m=m.toString();
  const l=m.length;
  return m.substring(0, Math.min(3,l) ) +
    m.substring(Math.min(6,l-1), Math.min(8,l) ) +
    m.slice(-3 );
}




/**
 * 
 * utility for handling markdown asterisks
 */
function stripWhitespaceAndAsterisks(str) {
  if (typeof str !== 'string') {
    return str;
  }
  // Trim whitespace from the beginning
  let trimmedStr = str.trimStart();
  // Remove asterisks from the beginning
  while (trimmedStr && trimmedStr[0] === '*') {
    trimmedStr = trimmedStr.slice(1);
  }
  return trimmedStr.trimStart();
}


/**
 * 
 * Accordion view
 */

const  QuestionToggler=({Question,Answer, isActive, onSelect})=>{
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    isExpanded:isActive, duration:400
  })
  return (
  <>
  <style>
    {`
        .fa-chevron-up,
        .fa-chevron-down {
          transition: transform 0.3s ease-in-out;
        }

        .fa-chevron-up {
          transform: rotate(180deg);
        }`}
  </style>
  <div  className='accordion-child-container-top'  style={{borderBottom:'  red 1px solid'}}>
    <div className="accordion-child-toggle-header mb-0"
    >
      <div className=" h2  text-left    d-flex justify-content-between align-items-center"  
       style={{display:'flex' ,justifyContent: 'space-between'}}
       {...getToggleProps({
        onClick: onSelect,
      })} >
        <div
        style={{ width: 'auto'}}
        >
      {stripWhitespaceAndAsterisks(Question)}
      </div>
      <span style={{display:'inline', position:'relative',
        transition: 'transform 0.5s ease',
        transform: isExpanded ?  'rotate(270deg)' : 'rotate(90deg)'
       }}    >
        <TriangleSVG fill='var(--purple-help)'  width="44" height="44" />
      </span>
      </div>
    </div>

    <div  {...getCollapseProps()} >
              <div style={{fontSize:'18px'}}>
               {Answer}
              </div>
    </div>
  </div>
  </>)
}



export function AccordionHelpQs({helpqs}){
  const [activeIndex, setActiveIndex] = useState(null);
  const [parentId, __setP] = useState("helpaccordion-"+Math.random().toString().slice(2,8))
  const onSelect = i=>setActiveIndex(activeIndex === i ? null : i);

  if (Array.isArray(helpqs) & helpqs.length>0 ) return (
  <>
  <style></style>
  <div className='accordion accordion-container-parent-top' id={parentId}>
  {helpqs.map((h,i)=>{
    return h.Question && (<QuestionToggler 
   key={i}
   Question={h.Question} Answer={h.Answer}  isActive={i==activeIndex}
   onSelect= {() => setActiveIndex(activeIndex === i ? null : i)}
  />)})
  }
  </div>
  </>)
  else return null;
}










