import React, { useReducer, useEffect, useMemo, createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import JoyRide, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

// Initial state
const INITIAL_STATE = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: [],
};

// Action types
const START = 'START';
const RESET = 'RESET';
const STOP = 'STOP';
const NEXT_OR_PREV = 'NEXT_OR_PREV';
const RESTART = 'RESTART';
const RESET_LANG = 'RESET_LANG';

// Reducer function
const tourReducer = (state, action) => {
  switch (action.type) {
    case START:
      return { ...state, run: true };
    case RESET:
      return { ...state, stepIndex: 0 };
    case STOP:
      return { ...state, run: false };
    case NEXT_OR_PREV:
      return { ...state, ...action.payload };
    case RESTART:
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    case RESET_LANG:
      return { ...state, stepIndex: 0, ...action.payload };
    default:
      return state;
  }
};

// Example of generating tour steps
const useHomeTourSteps = () => {
  const { t, i18n } = useTranslation();

  const steps = useMemo(() => [
    {
      target: '.tour-register',
      content: t('home.tour.register'),
      disableBeacon: true,
    },
    {
      target: '.tour-account-password',
      content: t('home.tour.password'),
    },
    {
      target: '.tour-login-options-fb',
      content: t('home.tour.facebook'),
    },
    {
      target: '.tour-login-options-google',
      content: t('home.tour.google'),
    },
    {
      target: '.tour-login-options-linkedin',
      content: t('home.tour.linkedin'),
      skipBeacon: false,
    }
  ], [i18n.language, t]);

  return steps;
};

export const TourComponent0 = ({
    useTranslation=useTranslation, 
    useTourStepsContext,
    // steps, 
    // tourState,
    // dispatch, 
    // startTour, 
    maxtimes=2,
    localstoragevar='d4htour' 
}) => {
    const {i18n } = useTranslation();
    const {steps, tourState,dispatch, startTour} = useTourStepsContext();
    // startTourX = startTour;
    useEffect(() => {
      const tourmaxvar = parseInt(localStorage.getItem(localstoragevar)) || 0;
      if ( tourmaxvar<maxtimes) {
        localStorage.setItem(localstoragevar, tourmaxvar+1 );
        dispatch({ type: "START" });
      } else {
        //don't auto start tour
      }
    }, [/*dispatch*/]);
    useEffect(()=>{
      dispatch({type:"RESET_LANG", 
        payload:{steps}
        })
    },[i18n.language, steps, /*dispatch*/]);

    const callback = (data) => {
      const { action, index, type, status } = data;
  
      if (
        action === ACTIONS.CLOSE ||
        (status === STATUS.SKIPPED && tourState.run) ||
        status === STATUS.FINISHED
      ) {
        dispatch({ type: "STOP" });
      } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
        dispatch({
          type: "NEXT_OR_PREV",
          payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
        });
      }
    };
  
  
    return (
      <>
        <JoyRide   
          {...tourState}
          callback={callback}
          showSkipButton={true}
          styles={{
  
            tooltipContainer: {
              textAlign: "left",
            },
           buttonNext:{backgroundColor:'#cc3366' , color: 'white', fontWeight:'bold' }, 
            buttonBack: {
              marginRight: 10,
            },
            options: { zindex: 1000}
          }}
          locale={{
            last: "End tour",
          }}
        />
      </>
    );
  };
  
//Reusable hook to initialize state of Tour
export const useInitTourSteps = (useMakeTourSteps) => {
    const osteps = useMakeTourSteps();
    const { steps } = osteps;
    const [tourState, dispatch] = useReducer(tourReducer, { ...INITIAL_STATE, steps });
    const startTour = () => {
        dispatch({ type: "RESTART" });
    };
    return { steps, tourState, dispatch, startTour };
}

// const useMakeTourStepsVideo = () => {
//     const { t, i18n } = useTranslation();
//     const osteps = useMemo(()=> {
//         const steps = [
//         ];
//         return {steps};
//         }, [i18n.language, i18n, t])
//         return osteps;          
// }
