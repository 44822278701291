import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import Footer from "./Footer";
import { I18Picker } from "./I18nIntl";
import "../assets/css/bootstrap.scoped.css";

import { Banner } from "./homebanner/Banner"
import {Tour} from './helpsupport/StepthruHome'


const Home = () => {
  const { t, i18n } = useTranslation();
  useEffect(()=>{
    const docu = t("helpsupport.menu_documentation")
    console.log('language changed::', {docu});
    console.log('langgg::', i18n.language);
  },[i18n.language,t])
  return (
    <div id="home-container">
      <div
        style={{
          display: 'inline',
          position: 'absolute',
          right: '22vw',
          color: 'black',

        }}>
      </div>
      <div
        style={{
          display: 'inline',
          position: 'absolute',
          right: '22vw',
          color: 'black',
          top: '.5rem'
        }}>
        <I18Picker />
      </div>

      {/* <div className="mainlogoarea" /> */}
      <Mainlogo />
      {/* <Banner /> */}
      <Footer>
        <Tour />
      </Footer>
    </div>
  );
};

function Mainlogo(){
  const { t, i18n } = useTranslation();
  const img=t('main.main_logo')
  return (
    <>
    <style>
      {`
      .mainlogoarea.langimg {
        background-image: url("${img}");
        ${((i18n.language=='es')||(i18n.language=='cn'))&&'background-size: contain;'}
      }
      `}
    </style>
    <div className="mainlogoarea langimg" />
    </>
  )
}
export default Home;