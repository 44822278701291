import i18n from "../i18n";
import i18next from "i18next";

window.gg = i18next
window.ggg = i18next.languages

export function I18Picker({languages}) {
    languages = languages || [
      { value: 'es', label: 'Español' },
      { value: 'cn', label: '中文' },
      { value: 'en', label: 'English' },
    ]
  
      return(
        <div
         style={{
           display: 'inline',
           position: 'relative',
           right: '33vw',
           color: 'black',
  
         }}
        > 
        <select
          position='absolute'
          aria-label="language"
          // value={i18next.language}
          defaultValue="other"
          onChange={ evt => {
            evt.preventDefault()
            i18n.changeLanguage(evt.target.value)
            return
          }}>
          <option key={"other"} disabled value={"other"}
          >English,Español,中文..</option>
          {languages.map(({ label, value }) => (
            <option key={value} value={value} 
             >{label}</option>
          ))}
        </select>
        </div>
      )
    ;
  }

