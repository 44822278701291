import React, {  createContext, useContext, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { TourComponent0,  useInitTourSteps } from "./StepThruContext";
import { WithHelperDocs } from "./HelpMenus";


/**
 * @typedef {Object} TourContextType
 * @property {function} startTour - Function to start the tour.
 * @property {TourState} tourState - The current state of the tour.
 * @property {React.Dispatch<TourAction>} dispatch - Dispatch function to update the tour state.
 */
export const VideoTourContext = createContext(undefined);



export const useVideoTourContext = () => {
  const context = useContext(VideoTourContext);
  if (context === undefined) {
    throw new Error('useVideoTourContext must be used within a VideoTourContext Provider');
  }
  return context;
};


const useTOURSTEPS = () => {
    const { t, i18n } = useTranslation();
    const osteps = useMemo(() => {
        const steps = [
            {
                target: ".energy-level-text", // ".tour-video-energy-tempo",
                content: t("video.tour.energylevel"),// "Allows you to change your energy level in case you get tired, or want a higher energy level.",
                disableBeacon: true,
            },
            {
                target: ".tour-video-startvideo",
                content: t("video.tour.startbutton"),//
                // "When ready, press this button to start dancing!",
                // disableBeacon: true,
            },
            {
                target: ".tour-video-startvideo2",
                content: t("video.tour.startbutton"),//
                // "When ready, press this button to start dancing!",
            },
            {
                target: ".duration-text",//".tour-video-durationselector",
                content: t("video.tour.durationbutton"),//
                // "Change the duration of your dance session before dancing starts.",
            },
            {
                target: ".tour-video-camera-onoff",
                content: t("video.tour.cameraonoff"),//
                // "Turn your camera on or off.",
            },
            {
                target: ".tour-video-mic-onoff",
                content: t("video.tour.miconoff"),//
                // "Mute or unmute your microphone.",
            },
            {
                target: ".tour-video-camera-frontback",
                content: t("video.tour.frontbackcamera"),//
                // "Switch between the front and back camera, if your device has more than 1 camera.",
            },
            {
                target: ".tour-video-fullscreen",
                content: t("video.tour.fullscreen"),//
                // "Watch the video in full screen. Recommended for a better experience.",
            },
            // {
            //     target: ".carousel-control-prev",
            //     content: t("video.tour.prevenergylevelbutton"),//
            //     // "Go to the previous dance energy level. This is another way to change the energy level or tempo of the dance",
            // },
            // {
            //     target: ".carousel-control-next",
            //     content: t("video.tour.nextenergylevelbutton"),//
            //     // "Go forward to the next dance energy level. This is another way to change the energy level or tempo of the dance",
            // },
            {
                target: ".tour-video-helpmenu",
                content: t("video.tour.helpmenu"),//
                // "Access the help menu to get more information.",
            },
            {
                target: 'a[href="/welcome"]',//".tour-video-gohome",
                content: t("video.tour.gobackbutton"),//
                // "Go back to the welcome screen and select a different dance option.",
            },
            {
                target: 'a[href="/profile"]',//".tour-video-profile",
                content: t("video.tour.profilebutton"),//
                // "Navigate to your profile page to make edits.",
            },
            {
                target: 'a[href="/"]',//".tour-video-logout",
                content: t("video.tour.logoutbutton"),//
                // "Logout and exit.",
            },
            {
                target: ".tour-video-completedance",
                content: t("video.tour.completionbutton"),//
                // "Mark your dance session complete and get a treat!",
            },
        ];
        return { steps };
    }, [i18n.language, t])
    console.log("+useHomTOUR ostps::", { osteps })
    return osteps;
};

export function VideoHelpProvider({children}) {
    const { steps, tourState, dispatch, startTour } = useInitTourSteps(useTOURSTEPS);

    return (
        <VideoTourContext.Provider
            value={{ steps, tourState, dispatch, startTour }}
        >
            <WithHelperDocs startTour={startTour} className={["tour-video-helpmenu"]}>
                {children}
                <VideoTour />
            </WithHelperDocs>
        </VideoTourContext.Provider>
    )
}

export function VideoTour(){
    // const = ()
    return (<TourComponent0 useTranslation={useTranslation}  useTourStepsContext={useVideoTourContext} localstoragevar="videotourctr" />)
}