import * as React from "react";
import { useTranslation } from 'react-i18next';


import {HelpLogo, HelperHelpDocsIcon} from "./HelperIcons";
import { Link } from "react-router-dom";
import { Tour } from "./StepthruHome";


export const HelpMenuList = ({ children, style, className }) => {
  const { t } = useTranslation();
  className = className && Array.isArray(className) ? className : className = [className];
  return (
    <div className={["dropdown", ...className]} style={{ ...style }}  >
      <span className={['btn', 'btn-secondary', 'dropdown-toggle']} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <div className=""
          style={{
            position: 'relative',
            width: '4rem',
            height: '4rem',
            marginTop: '-.8rem'
          }}
        >
          <HelpLogo props={{ width: '4rem', height: '4rem' }} />
        </div>
      </span>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li><Link className="dropdown-item" to="/help/faq">{/*Documentation*/ t("helpsupport.menu_documentation")}</Link></li>
        {/* <li><a  className="dropdown-item"  onClick={()=> startTour() } >Tour</a></li> */}
      </ul>
    </div>
  )
}


export const HelperDocsMenuList = ({style, className, startTour}={}) => {
  const { t } = useTranslation();
  className = className && Array.isArray(className) ? className : className = [className];
  return (
      <div className={["dropdown", ...className]} style={{...style}}  >
<span className={['btn', 'btn-secondary', 'dropdown-toggle']} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  <div className=""  
          style={{
            position:'relative',       
          }}
          >
          <HelperHelpDocsIcon />
          </div>
</span>
<ul className="dropdown-menu dropdown-menu-right " aria-labelledby="dropdownMenuButton">
  <li><Link  className="dropdown-item" to="/help/faq">{/*Documentation*/ t("helpsupport.menu_documentation")}</Link></li>
  {(startTour!==false) ?<li><a  className="dropdown-item"  onClick={()=> startTour ?  startTour() : Tour.startTour() } >{/*Tour*/ t("helpsupport.menu_tour") }</a></li>:<></>}
</ul>
</div>
  )
}


export const WithHelperDocs = ({ children, style, className, startTour }) => {
  return (<>
    <div style={{ zIndex: 1000, position: 'fixed', right: '0px', top: '50%', transform: 'translateY(-50%)' }}>
      <HelperDocsMenuList style={style} className={className} startTour={startTour} />
    </div>
    {children}
  </>)
};

export const WithHelperDocsuseStart=({useStartTour ,  ...args})=>{
  const {startTour} = useStartTour();
  return WithHelperDocs({startTour, ...args});
}
