import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/login/Login";
import Signup from "./components/Signup";
import Home from "./components/Home";
import Welcome from "./components/Welcome";
import UserProfile from "./components/UserProfile";
import PasswordReset from "./components/PasswordReset";
import ResetSuccess from "./components/ResetSuccess";
import VideoContainer from "./components/video/VideoContainer";
import PrivateRoutes from "./utils/PrivateRoutes";
import { FinishedDancingModal } from "./components/emotion/FinishedDancingModal";
import { Suspense, lazy, useEffect, useState } from "react";
import i18n from "./i18n";  //need to import for initialization
import i18next from "i18next";  //need to import for initialization
import {HelpFAQPage } from "./components/helpsupport/HelpDocs";
import { WithHelperDocs } from "./components/helpsupport/HelpMenus";

import {VideoHelpProvider} from "./components/helpsupport/VideoTour"
function App() {

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/welcome" element={<WithHelperDocs startTour={false}><Welcome /></WithHelperDocs>} />
          <Route path="/video" element={<VideoHelpProvider><VideoContainer /></VideoHelpProvider>} />
          <Route path="/profile" element={<WithHelperDocs startTour={false}><UserProfile /></WithHelperDocs>} />
        </Route>

        <Route path="/" exact element={<Home />} />
        <Route path="/login/:userId" element={<WithHelperDocs startTour={false}><Login /></WithHelperDocs>} />
        <Route path="/login" element={<WithHelperDocs startTour={false}><Login /></WithHelperDocs>} />
        <Route path="/passwordreset" element={<PasswordReset />} />
        <Route path="/register" element={<WithHelperDocs startTour={false}><Signup /></WithHelperDocs>} />
        <Route path="/reset/:token" element={<ResetSuccess />} />
        <Route path="/help/FAQ" element={<HelpFAQPage />} />
        <Route path="/help" element={<HelpFAQPage />} />
      </Routes>
    </Suspense>
  );
} 

export default App;

