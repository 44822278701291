import React from "react";
import "../../assets/css/video.scoped.css"; // Main CSS file is bootstrap.scoped.css
import { saveEmotion } from "../../utils/usersAPI";
import { getMessage } from "../emotion/getMessage";
import startDancing from "../../assets/img/btn_StartVideo.png";
import pauseDancing from "../../assets/img/btn_StopVideo.png";
import homeButton from "../../assets/img/btn_home_Off3.svg";
import logOutButton from "../../assets/img/btn_LogOut_Off3.svg";
import completeDanceButton from "../../assets/img/btn_complete_dance_1.png";
import prevButton from "../../assets/img/btn_arrow_left.png";
import nextButton from "../../assets/img/btn_arrow_right.png";
import btn_profile from "../../assets/img/btn_profile.svg";
import connectSound from "../../assets/audio/OnConnectionSound.wav";
import helloSound from "../../assets/audio/HelloAudio.wav";

import { Link, Navigate } from "react-router-dom";
import YouTubePlayer from "./YouTubePlayer";
import EmotionWidget from "../emotion/EmotionWidget";
import * as usersAPI from "../../utils/usersAPI";

import Carousel from "react-bootstrap/Carousel";

// import VIDEO_LENGTHS from "../../utils/videoLengths";

import videos from "../../assets/video/videos";
import playlists from "../../assets/video/playlists";
import "../../assets/css/bootstrap.scoped.css";

import { io } from "socket.io-client";
import { FinishedDancingModal } from "../emotion/FinishedDancingModal";
import EmojiMessage from "../emotion/EmojiMessage";
import MediaContainer from "./MediaContainer";

import Floater from 'react-floater';


import i18n from 'i18next';
import { withTranslation, Trans } from 'react-i18next';
import {HelpLogo} from '../helpsupport/HelperIcons'
import { HelperDocsMenuList } from "../helpsupport/HelpMenus";
// import {Tour, startTour} from '../helpsupport/StepthruVideo'

let token = window.localStorage.getItem("token");
const headers = {
  Accept: "application/json, text/plain, */*",
  "Content-Type": "application/json",
  Authorization: token,
};

let startX = 0;
let startY = 0;
let initialX = 0;
let initialY = 0;

export class VideoContainer extends React.Component {
  constructor(props) {
    super(props);
    window.localStorage.setItem("time", 30);
    this.state = {
      displayEmotion: false,
      displayFinishedDancing: false,
      displayEmojiMessage: false,
      username: "",
      roomName: window.location.href.split("=")[1].toLowerCase(),
      twilioRoom: null,
      token: null,
      sessionId: "",
      videoLogInfo: {
        videoId: "0",
        videoSpeed: "",
        videoName: "",
      },
      filteredVideos: [],
      videoPlayers: [],
      videoEnergy: window.localStorage.getItem("speed")
        ? window.localStorage.getItem("speed").toString()
        : "",
      // disabled for now - need playlists in order to match video times with the buttons
      videoDuration:
        window.localStorage.getItem("time") &&
        window.localStorage.getItem("time") === 0
          ? ""
          : window.localStorage.getItem("time").toString(),
      playing: false,
      videoIsStarting: false,
      videoStarted: false,
      currentVideoIndex: 0,
      topVideoHeight: 0,
      topVideoWidth: 0,
      topVideoRef: React.createRef(),
      // Media Control variables
      isCameraVideoPopUp: false,
      isFullScreen: false,
      isMicOn: true,
      isCameraOn: true,
      isCameraMirrored: true,
    };

    this.afterDanceEmotion = 0;
    this.returnToLobby = this.returnToLobby.bind(this);
    this.currentVideoTimer = 0;
    this.currentVideoTime = 0;
    this.videoRefsArray = [];
    var CAMERA_POP_UP_WIDTH = 200;
    //this.videoRef = React.createRef();
    this.socket = io(process.env.REACT_APP_SOCKET_URL, {
      // this.socket = io(`http://184.169.191.154:8000/`, {
      // this.socket = io("http://websocket.buddydance.com:8000/", {
      withCredentials: true,
      extraHeaders: {
        "my-custom-header": this.state.roomName.toLowerCase(),
      },
    });
    // console.log("videoContainer socket.io", this.socket);
    for (const i of playlists) {
      this.videoRefsArray.push(React.createRef());
    }
  }

  openFullscreen = () => {
    this.setState({ isFullScreen: true });
    var elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };
  closeFullscreen = () => {
    this.setState({ isFullScreen: false });
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  };

  removeMouseMoveEvent = (params) => {
    const bottomDiv = document.querySelector(".bottom-container-div");
    if (bottomDiv !== null) {
      bottomDiv.removeEventListener("mousemove", this.OnDrag);
    }
  };

  // Function that get current position of the bottom div which holds the camera videos and trasform the div to the mouse movement
  OnDrag = ({ movementX, movementY }) => {
    const bottomDiv = document.querySelector(".bottom-container-div");
    let maxX = window.innerWidth;
    let maxY = window.innerHeight;
    let getStyle = window.getComputedStyle(bottomDiv);
    let left = parseFloat(getStyle.left);
    let top = parseFloat(getStyle.top);
    let width = parseFloat(getStyle.width);
    let height = parseFloat(getStyle.height);
    let bottomBarHeight = 60;

    console.log(`(${left}, ${top})`);
    // Move the pop-up if inside the screen
    if (left >= 0 && left <= maxX - width) {
      bottomDiv.style.right = "";
      bottomDiv.style.left = `${left + movementX}px`;
      bottomDiv.style.top = `${top + movementY}px`;
    }
    if (left < 0) {
      // console.log('LEFT BORDER');
      bottomDiv.style.right = "";
      bottomDiv.style.left = `0px`;
    }
    if (left > maxX - width) {
      // console.log('RIGHT BORDER');
      bottomDiv.style.right = "";
      bottomDiv.style.left = `${maxX - width - 2}px`;
    }
    if (top < 60) {
      // console.log('TOP BORDER');
      bottomDiv.style.right = "";
      bottomDiv.style.top = `${60}px`;
    }
    if (top >= maxY - height - bottomBarHeight) {
      // console.log('BOTTOM BORDER');
      bottomDiv.style.right = "";
      bottomDiv.style.top = `${maxY - height - bottomBarHeight - 2}px`;
    }
  };

  handleDrag = () => {
    console.log("HANDLE DRAG");

    const bottomDiv = document.querySelector(".bottom-container-div");

    // ON DRAGGING
    bottomDiv.addEventListener("mousemove", this.OnDrag);
    bottomDiv.addEventListener("touchmove", function(event) {
      const touch = event.touches[0];
      const movementX = touch.clientX - startX;
      const movementY = touch.clientY - startY;

      // Call OnDrag to move the div based on touch movement
      this.OnDrag({ movementX, movementY });
    });

    // END
    document.addEventListener("mouseup", () => {
      this.removeMouseMoveEvent();
    });
    document.addEventListener("touchend", () => {
      bottomDiv.removeEventListener("touchmove", this.OnDrag);
    });
  };

  joinRoom = async () => {
    this.setState({ twilioRoom: this.state.roomName.toLowerCase() });
  };

  returnToLobby() {
    this.setState({ twilioRoom: null });
  }

  logVideoEvent = async (event, videoAction) => {
    //console.log("videoContainer props: ", this.props);
    //console.log("Log Video event fired in VideoContainer component: "),
    // videoAction,
    // this.state.filteredVideos[this.state.currentVideoIndex].id,
    // this.state.filteredVideos[this.state.currentVideoIndex].speed,
    // this.state.filteredVideos[this.state.currentVideoIndex].title);
    let videoSpeedToLog = this.state.filteredVideos[
      this.state.currentVideoIndex
    ].speed;
    let videoSpeedConvertedToWord;

    if (videoSpeedToLog === "1") {
      videoSpeedConvertedToWord = "low";
    } else if (videoSpeedToLog === "2") {
      videoSpeedConvertedToWord = "medium";
    } else if (videoSpeedToLog === "3") {
      videoSpeedConvertedToWord = "high";
    } else {
      videoSpeedConvertedToWord = "";
    }

    const values = {
      user: {
        username: this.state.username,
      },
      videoId: this.state.filteredVideos[this.state.currentVideoIndex].id,
      videoSpeed: videoSpeedConvertedToWord,
      videoTitle: this.state.filteredVideos[this.state.currentVideoIndex].title,
      event: {
        action: videoAction,
        timeElapsed: this.currentVideoTime,
      },
      sessionId: "need to add",
    };

    const result = await usersAPI.logVideoEvent(values);
    //console.log("Log video event: ", values);
  };

  handleLogVideoStart = (event) => {
    //console.log("Video Started: ", event);
    this.currentVideoTime = 0;
    this.logVideoEvent(event, "Video Started");
    // need to code log event to database via service
  };

  handleVideoEnded = (event) => {
    // console.log(
    //   "Video Ended: ",
    //   event,
    //   this.state.currentVideoIndex,
    //   this.state.videoPlayers.length
    // );
    this.setState({ videoStarted: false });
    if (this.state.currentVideoIndex < this.state.videoPlayers.length - 1) {
      this.logVideoEvent(event, "Video Finished");
      this.setState({ currentVideoIndex: this.state.currentVideoIndex + 1 });
      this.setState({ playing: false }, () => this.handlePlayPause());
      //this.handleSlideChanged(this.state.currentVideoIndex+1);
    } else {
      this.logVideoEvent(event, "Video and Playlist Finished");
      this.setState({ playing: false });
      this.setState({ displayFinishedDancing: true });
    }
  };

  handleModalResponse = (response) => {
    this.setState({ displayFinishedDancing: false });
    if (response === "yes") {
      this.setState({ displayEmotion: true });
    } else {
    }
  };

  handleEmotion = async (emotionId) => {
    const emotion = getMessage(emotionId);
    const result = await saveEmotion({
      username: this.state.username,
      emotion: emotion?.emotion,
      afterDance: true,
    });
    //console.log(
    //   "Emotion after dance saving result is: ",
    //   result,
    //   this.state.username,
    //   emotion?.emotion
    // );
    this.afterDanceEmotion = emotion?.emotion || 0;
    this.setState({ displayEmotion: false });
    this.setState({ displayEmojiMessage: true });
  };

  handleEmojiClick = () => {
    this.setState({ displayEmojiMessage: false });
  };

  handleSlideChanged = async (event) => {
    //console.log("slide changed event: ", event);
    // disabled for now - used to test
    // this.setState({ displayFinishedDancing: true });
    this.setState({ videoStarted: false });
    if (this.state.playing) {
      this.logVideoEvent(event, "Video Changed While Playing");
      await this.handlePlayPause();
    }
    if (this.currentVideoTime > 0) {
      this.logVideoEvent(event, "Video Changed While Paused");
      this.currentVideoTime = 0;
    }
    this.setState({ currentVideoIndex: event });
    this.socket.emit("change-video", {
      room: this.state.roomName,
      videoEnergy: this.state.videoEnergy,
      videoDuration: this.state.videoDuration,
      currentVideoIndex: event,
    });
    //console.log("slide changed refs: ", this.videoRefs);
  };

  videoListing = (isReceiving = false) => {
    //console.log(this.state.videoEnergy, this.state.videoDuration);
    // console.log(
    //   "Local Storage: ",
    //   window.localStorage,
    //   this.state.videoEnergy,
    //   this.state.videoDuration
    // );
    //let filteredVideos = playlists;
    let filteredPlaylists;
    const filteredVideos = [];
    //let filteredVideos = videos.filter(video)
    //console.log(
    //  "filteredPlaylists: ",
    //  filteredPlaylists,
    //  this.state.videoEnergy,
    //  this.state.videoDuration
    //  );

    if (this.state.videoEnergy && this.state.videoDuration) {
      filteredPlaylists = playlists.filter((playlist) => {
        //console.log(this.state.videoEnergy, this.state.videoDuration);
        return (
          playlist.speed === this.state.videoEnergy &&
          playlist.time === this.state.videoDuration
          // Number.parseInt(video.time) >=
          //   VIDEO_LENGTHS[this.state.videoDuration].start &&
          // Number.parseInt(video.time) <=
          //   VIDEO_LENGTHS[this.state.videoDuration].end
        );
      });
    } else if (this.state.videoEnergy) {
      //console.log("----- Hit videoEnergy filter");
      filteredPlaylists = playlists.filter(
        (playlist) => playlist.speed === this.state.videoEnergy
      );
    } else if (this.state.videoDuration) {
      filteredPlaylists = playlists.filter((playlist) => {
        return (
          playlist.time === this.state.videoDuration
          // Number.parseInt(video.time) >=
          //   VIDEO_LENGTHS[this.state.videoDuration].start &&
          // Number.parseInt(video.time) <=
          //   VIDEO_LENGTHS[this.state.videoDuration].end
        );
      });
    }

    filteredPlaylists.forEach((playlist) => {
      playlist.videos.forEach((videoToMatch) => {
        filteredVideos.push(videos.find((video) => video.id === videoToMatch));
      });
    });

    //console.log("filteredVideos in videoListing: ", filteredVideos);
    this.setState({ filteredVideos: filteredVideos, currentVideoIndex: 0 });
    const videoPlayers = filteredVideos.map((video, idx) => {
      //this.videoRefs.push(React.CreateRef);
      return (
        <div id="vimeoPlayerContainer" key={`video_${idx}`}>
          <YouTubePlayer
            id={idx}
            currentId={this.state.currentVideoIndex}
            videoId={video.videoId}
            // vimeoId={video.vimeoId}
            youTubeUrl={video.url}
            ref={this.videoRefsArray[idx]}
            playing={this.state.playing}
            videoHeight={this.state.topVideoHeight}
            videoWidth={this.state.topVideoWidth}
            goToNextVideo={this.handleVideoEnded}
            logVideoStart={this.handleLogVideoStart}
            isCameraVideoPopUp={this.state.isCameraVideoPopUp}
          />
        </div>
        // <img key={video.id} id={video.id} />
      );
    });
    //console.log("videoPlayers map: ", videoPlayers);
    //console.log("videoRefs: ", this.videoRefs);
    this.setState({ videoPlayers: videoPlayers });
    if (!isReceiving) {
      this.socket.emit("change-video", {
        room: this.state.roomName.toLowerCase(),
        videoEnergy: this.state.videoEnergy,
        videoDuration: this.state.videoDuration,
        currentVideoIndex: this.state.currentVideoIndex,
      });
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleVideoEnergy = (event) => {
    console.log(`ENERGY LEVEL: ${event.target.id}`);
    event.preventDefault();
    this.setState({ videoStarted: false }, () => {
      if (this.state.playing) {
        this.handlePlayPause();
      }
      this.setState({ videoEnergy: event.target.id }, () =>
        this.videoListing()
      );
    });
  };

  handleVideoDuration = (event) => {
    this.setState({ videoStarted: false }, () => {
      if (this.state.playing) {
        this.handlePlayPause();
      }
      this.setState({ videoDuration: event.target.id }, () =>
        this.videoListing()
      );
    });
  };

  setVideoDuration = (event, duration = "30") => {
    this.setState({ videoStarted: false }, () => {
      if (this.state.playing) {
        this.handlePlayPause();
      }
      this.setState({ videoDuration: duration }, () => this.videoListing());
    });
    console.log("DEFAULT DURATION SET");
  };

  handleEndDanceClick = () => {
    this.setState({ displayFinishedDancing: true });
    if (this.state.playing) this.handlePlayPause();
  };

  handlePlayPause = (event) => {
    if (this.state.playing) {
      this.removeControlBarsAnimation();
    } else {
      this.addControlBarsAnim();
    }
    this.expandImages();
    if (this.state.playing && this.state.videoStarted) {
      // Pausing video
      clearInterval(this.currentVideoTimer);
      //console.log("Video Paused with this time elapsed: ", this.state.videoId, this.currentVideoTime);
    } else if (this.state.playing && !this.state.videoStarted) {
      // changed to new video - stopping current one
      // console.log(
      //   "changed to new video - stopping current one - videoStarted: ",
      //   this.state.videoStarted,
      //   "videoPlaying: ",
      //   this.state.playing
      // );
      clearInterval(this.currentVideoTimer);
    } else {
      // starting video - either paused or new play

      this.setState({ videoIsStarting: true }, () => {
        // console.log(
        //   "starting video - videoIsStarting: ",
        //   this.state.videoIsStarting
        // );

        this.currentVideoTimer = setInterval(
          () => (this.currentVideoTime += 1),
          1000
        );

        setTimeout(
          () => {
            this.setState({ videoIsStarting: false });
          },
          this.state.videoIsStarting
            ? this.state.videoStarted
              ? 3 * 1000
              : 5 * 1000
            : 0
        );
        //console.log("Video started with this much time elapsed: ", this.currentVideoTime);
      });
    }

    this.setState({ playing: !this.state.playing }, () => {
      //console.log("HandlePlayPause current Id: ", this.state.currentVideoIndex);
      //console.log("HandlePlayPause playing: ", this.state.playing);
      //console.log("handlePlay ref array: ", this.videoRefsArray);
      //console.log("handleplay videoRef:", this.videoRef);
      this.videoRefsArray[this.state.currentVideoIndex].current.setPlaying(
        this.state.videoStarted
      );

      if (this.state.playing) {
        this.setState({ videoStarted: true });
      }

      if (event) {
        this.socket.emit("play-pause-video", {
          room: this.state.roomName.toLowerCase(),
          isVideoPlaying: this.state.playing,
        });
      }
    });
  };

  componentDidMount() {
    // See https://www.twilio.com/docs/video/tutorials/user-identity-access-tokens for information
    this.setState(
      {
        topVideoHeight: this.state.topVideoRef.current.clientHeight - 0,
        topVideoWidth: this.state.topVideoRef.current.clientHeight * 1.5625,
        // Possible solution for iphone video player problem
        videoEnergy: "2",
        videoDuration: "30",
      },
      () => {
        //("topvideoheight: ", this.state.topVideoHeight, this.state.topVideoWidth)
        this.videoListing();
      }
    );

    usersAPI.getUserData().then((data) => {
      const image = data.user.image;
      const username = data.user.username;
      const fname = data.user.fname;
      const lname = data.user.lname;
      const profileType = data.user.profile_type || "private";

      this.setState({
        ...this.state,
        fname: fname,
        lname: lname,
        username: username,
        profileImage: image,
        profileType: profileType,
      });
    });

    let username = null;
    if (this.props.location && this.props.location.state) {
      username = this.props.location.state.username;
      this.setState({
        username: username,
      });
    } else {
      username = window.localStorage.getItem("username");
      this.setState({
        username: username,
      });
    }
    this.joinRoom();
    // if (!roomname || roomname === "") {
    //   fetch(`${api}/python/app`, { headers })
    // .then(res => res.json())
    // .then(res => console.log("==== Response: ", res));
    // }

    this.socket.on("connect", (data) => {
      const transport = this.socket.io.engine.transport.name; // in most cases, "polling"
      // console.log("socket connect transport: ", transport);
      // const audio = new Audio(helloSound);
      // audio.play();
    });

    this.socket.io.on("error", (error) => {
      console.log("socket error: ", error);
    });

    this.socket.on("reconnect", (attempt) => {
      // console.log("reconnect attempt #: ", attempt);
    });

    this.socket.io.engine.on("upgrade", () => {
      const upgradedTransport = this.socket.io.engine.transport.name; // in most cases, "websocket"
      // console.log("socket upgrade upgradedTransport: ", upgradedTransport);
    });

    this.socket.on("video-state", (data) => {
      if (this.state.playing !== data.isVideoPlaying) {
        this.handlePlayPause();
      }
    });
    this.socket.on("video-index", (data) => {
      this.setState({ videoStarted: false }, () => {
        if (this.state.playing) {
          this.handlePlayPause();
        }
        if (this.state.currentVideoIndex !== data.currentVideoIndex) {
          this.setState(
            { currentVideoIndex: data.currentVideoIndex },
            () => {}
          );
        } else {
          this.setState(
            {
              videoEnergy: data.videoEnergy,
              videoDuration: data.videoDuration,
            },
            () => {
              this.videoListing(true);
            }
          );
        }
      });
      // if (this.state.playing) {
      //   this.handlePlayPause();
      // }
    });

    // Open Main Fullscreen Mode
    setTimeout(() => {
      this.toggleCameraVideoPopUp();
      this.resizeCameraVideos();
    }, 150);

    // Handle On Mouse Move Control Bar Animations
    document.addEventListener("mousemove", () => {
      this.removeControlBarsAnimation();
      setTimeout(() => {
        this.addControlBarsAnim();
      }, 2500);
    });

    window.addEventListener("resize", () => {
      this.resizeCameraVideos();
      this.ajustCameraVideoLayouts();
      this.ToggleVisibilityRotatePhonePopup();
    });

    // Potential fix for iphone loading problem
    setTimeout(() => {
      this.setVideoDuration();
    }, 500);

    this.expandImages();

    setTimeout(() => {
      this.ajustCameraVideoLayouts();
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isCameraVideoPopUp !== this.state.isCameraVideoPopUp) {
      const updatedVideoPlayers = this.state.filteredVideos.map(
        (video, idx) => {
          //this.videoRefs.push(React.CreateRef);
          return (
            <div id="vimeoPlayerContainer" key={`video_${idx}`}>
              <YouTubePlayer
                id={idx}
                currentId={this.state.currentVideoIndex}
                videoId={video.videoId}
                // vimeoId={video.vimeoId}
                youTubeUrl={video.url}
                ref={this.videoRefsArray[idx]}
                videoHeight={this.state.topVideoHeight}
                videoWidth={this.state.topVideoWidth}
                goToNextVideo={this.handleVideoEnded}
                logVideoStart={this.handleLogVideoStart}
                isCameraVideoPopUp={this.state.isCameraVideoPopUp}
              />
            </div>
            // <img key={video.id} id={video.id} />
          );
        }
      );
      this.setState({ videoPlayers: updatedVideoPlayers });
    }
  }

  componentWillUnmount() {
    //this.handleSlideChanged();
    this.socket.close();
    this.handleLeave();
  }

  handleLeave = (event) => {
    this.setState({ twilioRoom: null });
  };

  handleLogout = (event) => {
    window.localStorage.clear();
    //TODO: Complete for logout document.cookie(access_token:'');
  };

  resizeCameraVideos = () => {
    // Camera Videos
    // const cameraVideoContainer = document.querySelector('.camera-video-container');
    const localVideo = document.querySelector(".local-video");
    const remoteVideo = document.querySelector(".remote-video");

    // if (cameraVideoContainer === null) {
    //   return;
    // }
    if (localVideo === null) {
      return;
    }
    if (remoteVideo === null) {
      return;
    }

    if (!this.state.isFullScreen) {
      if (window.innerWidth <= 700) {
        localVideo.style.width = "100vw";
        remoteVideo.style.width = "100vw";
        localVideo.style.height = "25vh";
        remoteVideo.style.height = "25vh";
        // cameraVideoContainer.style.flexDirection = 'column';
      } else {
        localVideo.style.width = "50vw";
        remoteVideo.style.width = "50vw";
        localVideo.style.height = "50vh";
        remoteVideo.style.height = "50vh";
        // cameraVideoContainer.style.flexDirection = 'row';
      }
      return;
    }

    if (localVideo !== null && remoteVideo !== null) {
      if (window.innerHeight <= 500) {
        localVideo.style.width = "auto";
        remoteVideo.style.width = "auto";
        localVideo.style.height = window.innerHeight / 4 + "px";
        remoteVideo.style.height = window.innerHeight / 4 + "px";
      }
      if (window.innerWidth <= 500) {
        localVideo.style.width = window.innerWidth / 3 + "px";
        remoteVideo.style.width = window.innerWidth / 3 + "px";
        localVideo.style.height = "auto";
        remoteVideo.style.height = "auto";
      }
      if (window.innerWidth > 500 && window.innerHeight > 500) {
        localVideo.style.width = "230px";
        remoteVideo.style.width = "230px";
        localVideo.style.height = "178.5px";
        remoteVideo.style.height = "178.5px";
      }
    }
  };

  ajustCameraVideoLayouts = () => {
    const cameraVideoContainer = document.querySelector(
      "#camera-video-container"
    );
    if (cameraVideoContainer != null) {
      if (window.innerWidth >= 1100) {
        cameraVideoContainer.style.flexDirection = "column";
      } else {
        if (window.innerHeight <= 800 && window.innerWidth >= 600) {
          cameraVideoContainer.style.flexDirection = "column";
        } else {
          cameraVideoContainer.style.flexDirection = "row";
        }
      }
    }
  };

  ToggleVisibilityRotatePhonePopup = () => {
    if (window.innerWidth <= 500) {
      const popup = document.getElementById("turn-phone-popup");
      if (popup == null) {
        return;
      }
      if (popup.style.display == "") {
        popup.style.display = "none";
      } else {
        popup.style.display = "";
      }
    }
  };

  toggleCameraRotation = () => {
    const video = document.getElementsByClassName("local-video");
    let factor = this.state.isCameraMirrored ? "1" : "-1";
    video[0].style.transform = "scaleX(" + factor + ")";
    this.setState({ isCameraMirrored: !this.state.isCameraMirrored });
  };

  toggleVideoState = () => {
    this.setState({ isCameraOn: !this.state.isCameraOn });
  };

  toggleAudioState = () => {
    this.setState({ isMicOn: !this.state.isMicOn });
  };

  addControlBarsAnim = () => {
    const NavBtnDiv = document.querySelector(".nav-btn-div");
    const ControlButtonDiv = document.querySelector(".control-button-div");

    if (this.state.playing) {
      if (NavBtnDiv !== null && ControlButtonDiv !== null) {
        NavBtnDiv.style.transition = "opacity 2.5s";
        ControlButtonDiv.style.transition = "opacity 2.5s";
        NavBtnDiv.style.opacity = 0;
        ControlButtonDiv.style.opacity = 0;
      }
    } else {
      if (NavBtnDiv !== null && ControlButtonDiv !== null) {
        NavBtnDiv.style.opacity = 1;
        ControlButtonDiv.style.opacity = 1;
      }
    }
  };

  removeControlBarsAnimation = () => {
    const NavBtnDiv = document.querySelector(".nav-btn-div");
    const ControlButtonDiv = document.querySelector(".control-button-div");
    // RESET
    if (
      NavBtnDiv !== null &&
      ControlButtonDiv !== null &&
      NavBtnDiv !== undefined &&
      ControlButtonDiv !== undefined
    ) {
      NavBtnDiv.style = "";
      ControlButtonDiv.style = "";
    }
  };

  // Get all components from the DOM tree and change their styles to fit a full screen mode
  toggleCameraVideoPopUp = (event) => {
    // Images
    const screenImgs = document.querySelectorAll("#screen-img");
    const startImgs = document.querySelectorAll("#start-img");
    // Next Video Btns
    const next = document.querySelector(".carousel-control-next");
    const prev = document.querySelector(".carousel-control-prev");
    if (next !== null) {
      next.style.display = "none";
    }
    if (prev !== null) {
      prev.style.display = "none";
    }
    // Dance Videos
    const videoCarousel = document.getElementById("videoCarousel");
    const carouselInner = videoCarousel.querySelector(".carousel-inner");
    const carouselItems = document.querySelectorAll(".carousel-item");
    const vimeoPlayerContainers = document.querySelectorAll(
      "#vimeoPlayerContainer"
    );
    const reactPlayers = document.querySelectorAll("#react-player");
    // Navbar menu
    const navBtnDiv = document.querySelector(".nav-btn-div");
    // Layout main divs
    const topDiv = document.querySelector(".top-container-div");
    const bottomDiv = document.querySelector(".bottom-container-div");
    const buddyVideoDiv = document.querySelector("#buddy-video-div");
    // Camera Videos
    // const cameraVideoContainer = document.querySelector('.camera-video-container');
    const localVideo = document.querySelector(".local-video");
    const remoteVideo = document.querySelector(".remote-video");
    // Controls divs
    const startVideoDiv1 = document.querySelector("#start-video-div");
    const startVideoDiv2 = document.querySelector("#start-video-div2");
    const controlButtonDiv = document.querySelector(".control-button-div");
    const cameraControls = document.getElementById("camera-controls");
    // If camera video is not pop up ajust style properties to the new layout else reset all styles to the previous.
    if (!this.state.isCameraVideoPopUp) {
      this.openFullscreen();
      if (navBtnDiv !== null) {
        navBtnDiv.style.background = "rgb(33, 33, 33, 1)";
        navBtnDiv.style.alignItems = "center";
      }
      if (startVideoDiv1 !== null) {
        startVideoDiv1.style.display = "none";
        startVideoDiv2.style.display = "flex";
      }
      if (videoCarousel !== null) {
        videoCarousel.style.height = "100vh";
        videoCarousel.style.width = "100vw";
        videoCarousel.style.backgroundColor = "#000";
      }
      if (carouselInner !== null) {
        carouselInner.style.height = "100vh";
        carouselInner.style.width = "100vw";
      }
      if (carouselInner.children !== null) {
        for (let i = 0; i < carouselInner.children.length; i++) {
          carouselInner.children[i].style.height = "100%";
          carouselInner.children[i].style.width = "100%";
        }
      }
      if (carouselItems !== null) {
        // console.log(carouselItems);
        for (let i = 0; i < carouselItems.length; i++) {
          carouselItems[i].style.width = "100%";
          carouselItems[i].style.height = "100%";
        }
      }
      if (vimeoPlayerContainers !== null) {
        for (let i = 0; i < vimeoPlayerContainers.length; i++) {
          vimeoPlayerContainers[i].style = "";
          vimeoPlayerContainers[i].style.width = "100%";
          vimeoPlayerContainers[i].style.height = "100%";
        }
      }
      if (reactPlayers !== null) {
        for (let i = 0; i < reactPlayers.length; i++) {
          reactPlayers[i].style.height = "100%";
          reactPlayers[i].style.width = "100%";
        }
      }
      if (screenImgs !== null) {
        for (let i = 0; i < screenImgs.length; i++) {
          screenImgs[i].style.width = "100%";
          screenImgs[i].style.height = "100%";
        }
      }
      if (startImgs !== null) {
        for (let i = 0; i < startImgs.length; i++) {
          startImgs[i].style.width = "300px";
        }
      }
      // if (cameraVideoContainer !== null) {
      //   cameraVideoContainer.style.flexDirection = 'column';
      // }
      if (localVideo !== null) {
        localVideo.style.width = "230px";
        localVideo.style.height = "178.5PX";
      }
      if (remoteVideo !== null) {
        remoteVideo.style.width = "230px";
        remoteVideo.style.height = "178.5px";
      }
      if (cameraControls !== null) {
        cameraControls.style.display = "none";
      }
      if (controlButtonDiv !== null) {
        controlButtonDiv.style.position = "absolute";
        controlButtonDiv.style.bottom = "-2px";
        controlButtonDiv.style.backgroundColor = "#212121";
        controlButtonDiv.style.height = "60px";
      }
      if (bottomDiv !== null) {
        let bottomDivHeight = bottomDiv.style.height;
        bottomDiv.style.position = "absolute";
        bottomDiv.style.height = "fit-content";
        bottomDiv.style.right = "0px";
        bottomDiv.style.top = "60px"; //window.innerHeight/2 - bottomDivHeight*0.5  + 'px'
        bottomDiv.style.cursor = "grab";
        bottomDiv.style.zIndex = 1000;
      }
      if (buddyVideoDiv !== null) {
        buddyVideoDiv.style.height = "100%";
        buddyVideoDiv.style.width = "100%";
      }
      if (topDiv !== null) {
        topDiv.style.height = "100vh";
      }
      if (bottomDiv !== null) {
        // Add event listeners to video pop-up div so users can drag the window
        bottomDiv.addEventListener("mousedown", this.handleDrag);
        bottomDiv.addEventListener("touchstart", function(event) {
          const getStyle = window.getComputedStyle(bottomDiv);
          startX = event.touches[0].clientX;
          startY = event.touches[0].clientY;
          initialX = parseFloat(getStyle.left);
          initialY = parseFloat(getStyle.top);
        });
      }
      this.setState({ isCameraVideoPopUp: true });
    } else {
      // Top Div
      if (navBtnDiv !== null) {
        // navBtnDiv.style = "";
      }
      if (startVideoDiv1 !== null) {
        // startVideoDiv1.style = "";
      }
      if (startVideoDiv2 !== null) {
        // startVideoDiv2.style.display = "none";
      }
      if (videoCarousel !== null) {
        // videoCarousel.style = "";
      }
      if (controlButtonDiv !== null) {
        // controlButtonDiv.style = "";
        controlButtonDiv.style.position = "absolute";
        controlButtonDiv.style.bottom = "-2px";
        controlButtonDiv.style.backgroundColor = "#212121";
        controlButtonDiv.style.height = "60px";
      }
      if (topDiv !== null) {
        topDiv.style = "";
      }
      if (vimeoPlayerContainers !== null) {
        for (let i = 0; i < vimeoPlayerContainers.length; i++) {
          vimeoPlayerContainers[i].style = "";
        }
      }
      if (reactPlayers !== null) {
        for (let i = 0; i < reactPlayers.length; i++) {
          reactPlayers[i].style = "";
          reactPlayers[i].style = "width: 696.875px; height: 464px;";
        }
      }
      if (startImgs !== null) {
        for (let i = 0; i < startImgs.length; i++) {
          startImgs[i].style = "";
        }
      }

      // Bottom Div
      if (bottomDiv !== null) {
        bottomDiv.style = "";
      }
      if (buddyVideoDiv !== null) {
        buddyVideoDiv.style = "";
      }
      // if (cameraVideoContainer !== null) {
      //   cameraVideoContainer.style.flexDirection = 'row';
      // }
      if (localVideo !== null) {
        localVideo.style.width = "50vw";
        localVideo.style.height = "auto";
      }
      if (remoteVideo !== null) {
        remoteVideo.style.width = "50vw";
        remoteVideo.style.height = "auto";
      }
      if (carouselInner !== null) {
        carouselInner.style.width = "100vw";
        carouselInner.style.height = "auto";
      }
      if (cameraControls !== null) {
        cameraControls.style.display = "";
      }
      if (carouselInner !== null) {
        for (let i = 0; i < carouselInner.children.length; i++) {
          carouselInner.children[i].style = "";
        }
      }
      this.removeMouseMoveEvent();
      if (bottomDiv !== null) {
        bottomDiv.removeEventListener("mousedown", this.handleMouseDrag);
      }
      this.setState({ isCameraVideoPopUp: !this.state.isCameraVideoPopUp });
      this.closeFullscreen();
    }
  };

  expandImages = (event) => {
    const screenImgs = document.querySelectorAll("#screen-img");
    const startImgs = document.querySelectorAll("#start-img");

    if (this.state.isCameraVideoPopUp) {
      if (screenImgs !== null) {
        for (let i = 0; i < screenImgs.length; i++) {
          screenImgs[i].style = "";
          screenImgs[i].style.width = "100%";
          screenImgs[i].style.height = "100%";
        }
      }
      if (startImgs !== null) {
        for (let i = 0; i < startImgs.length; i++) {
          startImgs[i].style = "";
          startImgs[i].style.width = "185px";
        }
      }
    } else {
      if (screenImgs !== null) {
        for (let i = 0; i < screenImgs.length; i++) {
          screenImgs[i].style.width = "646.875px";
          screenImgs[i].style.height = "414px";
        }
      }
      if (startImgs !== null) {
        for (let i = 0; i < startImgs.length; i++) {
          startImgs[i].style = "";
        }
      }
    }
  };

  render() {
    return (
      <>
        {this.state.displayFinishedDancing ? (
          <FinishedDancingModal
            handleModalResponse={this.handleModalResponse}
          />
        ) : null}

        {this.state.displayEmotion ? (
          <EmotionWidget handleEmotion={this.handleEmotion} />
        ) : null}

        {this.state.displayEmojiMessage ? (
          <EmojiMessage
            heading="Great Job!!"
            emotion={this.afterDanceEmotion}
            handleClick={this.handleEmojiClick}
          />
        ) : null}
        {(this.state.displayFinishedDancing ||
          this.state.displayEmotion ||
          this.state.displayEmojiMessage) && (
          <div className="modal-bkg-overlay" />
        )}

        <div id="turn-phone-popup">
          <div style={{ marginBottom: "20px" }}>Rotate your phone</div>

          <div id="rotatePhoneImage">
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="120px"
              height="120px"
              viewBox="0 0 1920.000000 1864.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,1864.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
              >
                <path
                  d="M8263 18605 l-35 -35 7 -209 7 -208 -363 -6 c-363 -6 -567 -19 -879
            -57 -1468 -180 -2880 -782 -4035 -1719 -433 -351 -847 -765 -1197 -1196 -883
            -1090 -1470 -2421 -1682 -3815 -48 -316 -91 -798 -84 -935 7 -130 73 -231 186
            -287 59 -29 76 -33 147 -33 71 0 89 4 147 33 76 37 120 83 156 161 22 48 27
            79 38 255 69 1049 305 1944 749 2841 356 720 804 1343 1380 1921 590 591 1199
            1031 1935 1398 1028 511 2113 765 3273 766 189 0 249 -3 252 -12 2 -7 8 -109
            13 -225 8 -203 9 -214 32 -238 31 -33 80 -48 114 -35 30 11 1159 770 1193 802
            31 29 40 82 20 122 -13 27 -103 82 -641 389 -695 396 -670 385 -733 322z"
                />
                <path
                  d="M12333 18480 c-311 -33 -599 -143 -839 -323 -91 -68 -963 -935 -5323
            -5292 -2868 -2865 -5254 -5255 -5303 -5310 -466 -520 -566 -1245 -258 -1860
            125 -248 -33 -83 2619 -2736 1825 -1825 2447 -2440 2509 -2485 298 -213 614
            -316 972 -316 171 0 274 12 428 52 193 50 384 141 565 269 80 58 10627 10598
            10711 10706 362 461 452 1082 235 1629 -22 56 -60 137 -84 181 -113 207 -18
            109 -2589 2681 -1332 1332 -2450 2443 -2485 2469 -221 167 -478 277 -746 320
            -118 19 -309 26 -412 15z m326 -795 c98 -20 214 -67 291 -116 98 -63 4822
            -4784 4891 -4889 57 -86 103 -199 126 -308 19 -94 13 -306 -12 -397 -23 -86
            -96 -229 -154 -305 -27 -36 -2407 -2421 -5288 -5301 -5680 -5678 -5293 -5299
            -5477 -5370 -208 -80 -441 -79 -646 2 -169 68 -65 -32 -2621 2526 -2599 2600
            -2431 2425 -2498 2609 -102 278 -55 596 122 829 25 33 2405 2419 5289 5302
            5630 5628 5293 5298 5470 5371 40 17 105 37 143 45 92 20 275 21 364 2z"
                />
                <path
                  d="M18766 8524 c-104 -33 -190 -119 -220 -219 -8 -27 -17 -104 -21 -170
            -74 -1473 -575 -2860 -1456 -4035 -1120 -1494 -2779 -2514 -4603 -2830 -409
            -71 -719 -99 -1178 -107 -293 -5 -348 -4 -353 8 -2 8 -8 110 -13 226 l-8 213
            -29 30 c-37 39 -82 47 -127 23 -76 -40 -1145 -767 -1175 -799 -37 -37 -43 -77
            -19 -122 20 -39 1232 -732 1293 -739 39 -5 46 -2 79 31 l36 36 -7 208 -8 209
            354 6 c540 9 941 50 1419 142 1810 352 3424 1308 4625 2739 233 278 526 690
            712 1001 562 939 924 1976 1067 3055 34 253 58 547 60 715 1 131 -1 143 -25
            195 -37 79 -81 125 -155 161 -78 39 -172 47 -248 23z"
                />
              </g>
            </svg>
          </div>

          <div
            onClick={this.ToggleVisibilityRotatePhonePopup}
            style={{
              marginTop: "15px",
              color: "#ffffff99",
              cursor: "pointer",
            }}
          >
            Click to close
          </div>
        </div>

        <div className="grid-container">
          <div className="top-container-div" ref={this.state.topVideoRef}>
            <div className="nav-btn-div">
              <div id="room-name-tag" className="RoomNameTag">
                <span className="room-name-hide">Room name</span>
                <div>{this.state.roomName.toLowerCase()}</div>
              </div>
              <div
                id="navigation-btns"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="nav-btns">
                  <Link className="link" to="/welcome">
                    {/* <img src={homeButton} alt="" /> */}
                    <div id="outlinedPinkBtn">Home</div>
                  </Link>
                  <Link to="/profile">
                    {/* <img id="profileBtn" src={btn_profile} alt="" /> */}
                    <div id="outlinedPinkBtn">Profile</div>
                  </Link>
                  <Link className="link" to="/">
                    {/* <img src={logOutButton} alt="" onClick={this.handleLogout} /> */}
                    <div id="outlinedPinkBtn">Logout</div>
                  </Link>
                </div>
                <div
                  id="pinkBtn"
                  src={completeDanceButton}
                  alt="Finish the dance"
                  className="tour-video-completedance"
                  onClick={this.handleEndDanceClick}
                >
                  Finish :D
                </div>
              </div>
            </div>
            <Carousel
              id="videoCarousel"
              activeIndex={this.state.currentVideoIndex}
              onSelect={
                this.state.videoIsStarting ? null : this.handleSlideChanged
              }
              prevIcon={<img src={prevButton} alt="" />}
              nextIcon={<img src={nextButton} className="next-video-01" alt="" />}
              wrap={false}
              // onSlid={this.handleSlideChanged}
              interval={null}
              indicators={false}
            >
              {this.state.videoPlayers.map((player, idx) => {
                return <Carousel.Item key={idx}>{player}</Carousel.Item>;
              })}
            </Carousel>
          </div>

          <div className="bottom-container-div">
            <div id="start-video-div" />

            <div id="buddy-video-div">
              {this.state.twilioRoom ? (
                <MediaContainer
                  roomName={this.state.roomName.toLowerCase()}
                  returnToLobby={this.returnToLobby}
                  handleLogout={this.handleLogout}
                  socket={this.socket}
                  isMicOn={this.state.isMicOn}
                  isCameraOn={this.state.isCameraOn}
                  isFullScreen={this.state.isFullScreen}
                  username={this.state.username}
                  fname={this.state.fname}
                  lname={this.state.lname}
                  profileImage={this.state.profileImage}
                />
              ) : null}
            </div>
          </div>

          <div className="control-button-div">
            <div
              id="start-video-div2"
              style={{
                display: "none",
                position: "absolute",
                left: "50%",
                right: "50%",
                transform: "translateX(-50%)",
                alignItems: "center",
                order: 1,
                width: "max-content",
              }}
            >
              <div
                id="btnPlayDance"
                onClick={
                  this.state.videoIsStarting ? null : this.handlePlayPause
                }
                alt="Start Button"
                className={this.state.videoIsStarting ? ["btn-disabled", "tour-video-startvideo"] : ['tour-video-startvideo']}
              >
                {this.state.playing ? "Pause :)" : "Start  :)"}
              </div>
              <div
                id="btnPlaySmall"
                onClick={
                  this.state.videoIsStarting ? null : this.handlePlayPause
                }
                alt="Start Button"
                className={this.state.videoIsStarting ? "btn-disabled" : null}
              >
                {this.state.playing ? ( //if is playing
                  <svg
                    width="30px"
                    height="30px"
                    viewBox="-1 0 8 8"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <title>pause [#1006]</title>
                    <desc>Created with Sketch.</desc>
                    <defs />
                    <g
                      id="Page-1"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-227.000000, -3765.000000)"
                        fill="white"
                      >
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          <path
                            d="M172,3605 C171.448,3605 171,3605.448 171,3606 L171,3612 C171,3612.552 171.448,3613 172,3613 C172.552,3613 173,3612.552 173,3612 L173,3606 C173,3605.448 172.552,3605 172,3605 M177,3606 L177,3612 C177,3612.552 176.552,3613 176,3613 C175.448,3613 175,3612.552 175,3612 L175,3606 C175,3605.448 175.448,3605 176,3605 C176.552,3605 177,3605.448 177,3606"
                            id="pause-[#1006]"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                ) : (
                  //else
                  <svg
                    width="30px"
                    height="30px"
                    viewBox="-1 0 12 12"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <title>play [#1000]</title>
                    <desc>Created with Sketch.</desc>
                    <defs />
                    <g
                      id="Page-1"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-65.000000, -3803.000000)"
                        fill="white"
                      >
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          <path
                            d="M18.074,3650.7335 L12.308,3654.6315 C10.903,3655.5815 9,3654.5835 9,3652.8985 L9,3645.1015 C9,3643.4155 10.903,3642.4185 12.308,3643.3685 L18.074,3647.2665 C19.306,3648.0995 19.306,3649.9005 18.074,3650.7335"
                            id="play-[#1000]"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                )}
              </div>
            </div>

            <div id="filter-div" style={{ order: 0 }}>
              <div className="my-tooltip">
                <div className="tooltipText">
                  {this.state.isMicOn ? "Mute my audio" : "Unmute my audio"}
                </div>
                <div className="dropbtn tour-video-mic-onoff" onClick={this.toggleAudioState}>
                  {this.state.isMicOn ? (
                    <>
                      <svg
                        width="28px"
                        height="28px"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M8 5C8 2.79086 9.79086 1 12 1C14.2091 1 16 2.79086 16 5V12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12V5Z" />
                        <path d="M6.25 11.8438V12C6.25 13.525 6.8558 14.9875 7.93414 16.0659C9.01247 17.1442 10.475 17.75 12 17.75C13.525 17.75 14.9875 17.1442 16.0659 16.0659C17.1442 14.9875 17.75 13.525 17.75 12V11.8438C17.75 11.2915 18.1977 10.8438 18.75 10.8438H19.25C19.8023 10.8438 20.25 11.2915 20.25 11.8437V12C20.25 14.188 19.3808 16.2865 17.8336 17.8336C16.5842 19.0831 14.9753 19.8903 13.25 20.1548V22C13.25 22.5523 12.8023 23 12.25 23H11.75C11.1977 23 10.75 22.5523 10.75 22V20.1548C9.02471 19.8903 7.41579 19.0831 6.16637 17.8336C4.61919 16.2865 3.75 14.188 3.75 12V11.8438C3.75 11.2915 4.19772 10.8438 4.75 10.8438H5.25C5.80228 10.8438 6.25 11.2915 6.25 11.8438Z" />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        width="28px"
                        height="28px"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 1C13.6452 1 15.0585 1.99333 15.6728 3.41298L7.99997 11.0858V5C7.99997 2.79086 9.79083 1 12 1Z" />
                        <path d="M6.24997 12C6.24997 12.2632 6.26801 12.5245 6.30342 12.7823L4.25194 14.8338C3.92295 13.9344 3.74997 12.9761 3.74997 12V11.8438C3.74997 11.2915 4.19769 10.8438 4.74997 10.8438H5.24997C5.80226 10.8438 6.24997 11.2915 6.24997 11.8438V12Z" />
                        <path d="M7.3242 18.7971L3.76773 22.3535C3.3772 22.7441 2.74404 22.7441 2.35352 22.3535L1.64641 21.6464C1.25588 21.2559 1.25588 20.6227 1.64641 20.2322L20.2322 1.64644C20.6227 1.25591 21.2559 1.25591 21.6464 1.64644L22.3535 2.35354C22.744 2.74407 22.744 3.37723 22.3535 3.76776L16 10.1213V12C16 14.2091 14.2091 16 12 16C11.4457 16 10.9177 15.8873 10.4378 15.6835L9.13553 16.9857C9.99969 17.4822 10.986 17.75 12 17.75C13.525 17.75 14.9875 17.1442 16.0658 16.0659C17.1442 14.9875 17.75 13.525 17.75 12V11.8438C17.75 11.2915 18.1977 10.8438 18.75 10.8438H19.25C19.8023 10.8438 20.25 11.2915 20.25 11.8437V12C20.25 14.188 19.3808 16.2865 17.8336 17.8336C16.5842 19.0831 14.9753 19.8903 13.25 20.1548V23H10.75V20.1548C9.51944 19.9662 8.34812 19.5014 7.3242 18.7971Z" />
                      </svg>
                    </>
                  )}
                </div>
              </div>

              <div className="my-tooltip">
                <div className="tooltipText">
                  {this.state.isCameraOn ? "Turn off camera" : "Turn on camera"}
                </div>
                <div className="dropbtn tour-video-camera-onoff" onClick={this.toggleVideoState}>
                  {this.state.isCameraOn ? (
                    <>
                      <svg
                        width="28px"
                        height="28px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.2902 2C8.49207 2 6.89571 3.15059 6.3271 4.85641C6.26345 5.04736 6.10666 5.19256 5.91139 5.24138L5.10615 5.44269C3.28064 5.89906 2 7.53928 2 9.42097V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V9.42097C22 7.53928 20.7194 5.89906 18.8938 5.44268L18.0886 5.24138C17.8933 5.19256 17.7365 5.04736 17.6729 4.85641C17.1043 3.15059 15.5079 2 13.7098 2H10.2902ZM12 11C10.8954 11 10 11.8954 10 13C10 14.1046 10.8954 15 12 15C13.1046 15 14 14.1046 14 13C14 11.8954 13.1046 11 12 11ZM8 13C8 10.7909 9.79086 9 12 9C14.2091 9 16 10.7909 16 13C16 15.2091 14.2091 17 12 17C9.79086 17 8 15.2091 8 13Z"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        width="28px"
                        height="28px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289Z"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.84401 5.25822L5.10615 5.44269C3.28064 5.89906 2 7.53928 2 9.42097V17C2 19.7614 4.23858 22 7 22H17C18.5298 22 19.8992 21.3129 20.8163 20.2306L15.5085 14.9227C14.8285 16.1608 13.5123 17 12 17C9.79086 17 8 15.2091 8 13C8 11.4877 8.83921 10.1715 10.0773 9.49154L5.84401 5.25822ZM15.9763 12.5621C15.7739 10.7028 14.2972 9.22615 12.4379 9.0237L7.32157 3.90736C7.14245 3.72824 7.12339 3.44094 7.30037 3.2597C8.06949 2.47205 9.13844 2 10.2902 2H13.7098C15.5079 2 17.1043 3.15059 17.6729 4.85641C17.7365 5.04736 17.8933 5.19256 18.0886 5.24138L18.8938 5.44268C20.7194 5.89906 22 7.53928 22 9.42097V17C22 17.1894 21.9895 17.3763 21.969 17.5603C21.9268 17.9385 21.4745 18.0603 21.2053 17.7911L15.9763 12.5621ZM11.6215 11.0358C10.6979 11.2127 10 12.0248 10 13C10 14.1046 10.8954 15 12 15C12.9752 15 13.7873 14.3021 13.9642 13.3785L11.6215 11.0358Z"
                        />
                      </svg>
                    </>
                  )}
                </div>
              </div>

              <div className="custom-dropdown">
                <div className="dropdown-content">
                  <a href="">
                    <span
                      style={{
                        opacity: this.state.videoIsStarting ? ".3" : "1",
                      }}
                      onClick={
                        this.state.videoIsStarting
                          ? null
                          : this.handleVideoEnergy
                      }
                      id="1"
                      className={
                        this.state.videoEnergy === "1"
                          ? "filterBtn filterActive"
                          : "filterBtn"
                      }
                    >
                      Easy Breezy
                    </span>
                  </a>
                  <a href="">
                    <span
                      style={{
                        opacity: this.state.videoIsStarting ? ".3" : "1",
                      }}
                      onClick={
                        this.state.videoIsStarting
                          ? null
                          : this.handleVideoEnergy
                      }
                      ref={this.energyTwo}
                      id="2"
                      className={
                        this.state.videoEnergy === "2"
                          ? "filterBtn filterActive"
                          : "filterBtn"
                      }
                    >
                      Mellow Yellow
                    </span>
                  </a>
                  <a href="">
                    <span
                      style={{
                        opacity: this.state.videoIsStarting ? ".3" : "1",
                      }}
                      onClick={
                        this.state.videoIsStarting
                          ? null
                          : this.handleVideoEnergy
                      }
                      id="3"
                      className={
                        this.state.videoEnergy === "3"
                          ? "filterBtn filterActive"
                          : "filterBtn"
                      }
                    >
                      Dance Machine
                    </span>
                  </a>
                </div>
                <button className="dropbtn">
                  <svg
                    fill="white"
                    width="28px"
                    height="28px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13,9h6L8,22l3-10H5L10,2h7Z" />
                  </svg>
                  <span className="energy-level-text">Energy Level</span>
                </button>
              </div>
            </div>
            <div />

            <div id="time-div" style={{ order: 2 }}>
              {/* MORE -> Flip Camera */}
              <div className="dropdown my-tooltip">
                <div className="tooltipText">Mirror my Camera</div>
                <div className="dropbtn tour-video-camera-frontback" onClick={this.toggleCameraRotation}>
                  <svg
                    width="28px"
                    height="28px"
                    viewBox="0 0 56 56"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#fff"
                  >
                    <path d="M 7.8086 50.3477 L 48.1914 50.3477 C 53.0663 50.3477 55.5508 47.9102 55.5508 43.0820 L 55.5508 18.5430 C 55.5508 13.7149 53.0663 11.3008 48.1914 11.3008 L 42.7069 11.3008 C 40.8789 11.3008 40.3164 10.9258 39.2617 9.7539 L 37.3633 7.6445 C 36.2149 6.3555 35.0196 5.6523 32.5820 5.6523 L 23.2539 5.6523 C 20.8398 5.6523 19.6445 6.3555 18.4727 7.6445 L 16.5742 9.7539 C 15.5430 10.9023 14.9571 11.3008 13.1289 11.3008 L 7.8086 11.3008 C 2.9336 11.3008 .4492 13.7149 .4492 18.5430 L .4492 43.0820 C .4492 47.9102 2.9336 50.3477 7.8086 50.3477 Z M 37.9258 37.5976 L 33.5196 31.7852 C 32.9102 30.9883 33.3086 29.9571 34.2461 29.9571 L 37.4102 29.9571 C 37.4102 24.2383 33.4961 20.2539 27.9883 20.2539 C 26.2539 20.2539 24.8476 20.6758 23.4180 21.4492 C 21.8242 22.2227 20.6055 21.3086 20.6055 20.1602 C 20.6055 19.5976 20.8633 18.9649 21.5430 18.5430 C 23.0430 17.6289 25.3867 16.8320 27.9883 16.8320 C 35.4649 16.8320 40.8789 22.2695 40.8789 29.9571 L 43.6679 29.9571 C 44.6288 29.9571 44.9807 30.9649 44.3711 31.7852 L 39.9414 37.5976 C 39.4492 38.2539 38.4649 38.2773 37.9258 37.5976 Z M 27.9883 42.5898 C 20.5117 42.5898 15.1211 36.8945 15.1211 29.4649 L 12.3320 29.4649 C 11.3476 29.4649 11.0196 28.4336 11.6289 27.6133 L 16.0586 21.8008 C 16.5508 21.1680 17.5352 21.1211 18.0508 21.8008 L 22.4805 27.6133 C 23.0898 28.4336 22.6914 29.4649 21.7305 29.4649 L 18.5664 29.4649 C 18.5664 34.9023 22.4805 39.1445 27.9883 39.1445 C 29.7461 39.1445 31.1758 38.7227 32.5586 37.9727 C 34.1992 37.1523 35.3711 38.1367 35.3711 39.3320 C 35.3711 39.8945 35.0898 40.4336 34.4336 40.8789 C 32.9571 41.8398 30.5664 42.5898 27.9883 42.5898 Z" />
                  </svg>
                </div>
              </div>

              <div className="custom-dropdown">
                <div className="dropdown-content dropdown-content2">
                  <a>
                    <span
                      style={{
                        opacity: this.state.videoIsStarting ? ".3" : "1",
                      }}
                      onClick={
                        this.state.videoIsStarting
                          ? null
                          : this.handleVideoDuration
                      }
                      id="15"
                      className={
                        this.state.videoDuration === "15"
                          ? "TFilterBtn filterActive"
                          : "TFilterBtn"
                      }
                    >
                      15 min
                    </span>
                  </a>
                  <a>
                    <span
                      style={{
                        opacity: this.state.videoIsStarting ? ".3" : "1",
                      }}
                      onClick={
                        this.state.videoIsStarting
                          ? null
                          : this.handleVideoDuration
                      }
                      id="30"
                      className={
                        this.state.videoDuration === "30"
                          ? "TFilterBtn filterActive"
                          : "TFilterBtn"
                      }
                    >
                      30 min
                    </span>
                  </a>
                  <a>
                    <span
                      style={{
                        opacity: this.state.videoIsStarting ? ".3" : "1",
                      }}
                      onClick={
                        this.state.videoIsStarting
                          ? null
                          : this.handleVideoDuration
                      }
                      id="45"
                      className={
                        this.state.videoDuration === "45"
                          ? "TFilterBtn filterActive"
                          : "TFilterBtn"
                      }
                    >
                      45 min
                    </span>
                  </a>
                  <a>
                    <span
                      style={{
                        opacity: this.state.videoIsStarting ? ".3" : "1",
                      }}
                      onClick={
                        this.state.videoIsStarting
                          ? null
                          : this.handleVideoDuration
                      }
                      id="60"
                      className={
                        this.state.videoDuration === "60"
                          ? "TFilterBtn filterActive"
                          : "TFilterBtn"
                      }
                    >
                      60 min
                    </span>
                  </a>
                </div>
                <button className="dropbtn">
                  <svg
                    width="28px"
                    height="28px"
                    viewBox="0 0 24 24"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM3.00683 12C3.00683 16.9668 7.03321 20.9932 12 20.9932C16.9668 20.9932 20.9932 16.9668 20.9932 12C20.9932 7.03321 16.9668 3.00683 12 3.00683C7.03321 3.00683 3.00683 7.03321 3.00683 12Z" />
                    <path d="M12 5C11.4477 5 11 5.44771 11 6V12.4667C11 12.4667 11 12.7274 11.1267 12.9235C11.2115 13.0898 11.3437 13.2343 11.5174 13.3346L16.1372 16.0019C16.6155 16.278 17.2271 16.1141 17.5032 15.6358C17.7793 15.1575 17.6155 14.5459 17.1372 14.2698L13 11.8812V6C13 5.44772 12.5523 5 12 5Z" />
                  </svg>
                  <span className="duration-text" style={{ marginLeft: "5px" }}>
                    Duration
                  </span>
                </button>
              </div>

              <div className="my-tooltip">
                <div className="tooltipText">
                  {this.state.isFullScreen
                    ? "Exit Fullscreen"
                    : "Enter Fullscreen"}
                </div>
                {/* <div className="dropbtn" onClick={ this.state.isFullScreen ? this.closeFullscreen : this.openFullscreen }></div> */}
                <div className="dropbtn tour-video-fullscreen" onClick={this.toggleCameraVideoPopUp}>
                  {this.state.isFullScreen ? (
                    <>
                      <svg
                        width="28px"
                        height="28px"
                        viewBox="0 0 24 24"
                        fill="ffffff"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.7071 3.70711L16.4142 9H20C20.5523 9 21 9.44772 21 10C21 10.5523 20.5523 11 20 11H14.0007L13.997 11C13.743 10.9992 13.4892 10.9023 13.295 10.7092L13.2908 10.705C13.196 10.6096 13.1243 10.4999 13.0759 10.3828C13.0273 10.2657 13.0004 10.1375 13 10.003L13 10V4C13 3.44772 13.4477 3 14 3C14.5523 3 15 3.44772 15 4V7.58579L20.2929 2.29289C20.6834 1.90237 21.3166 1.90237 21.7071 2.29289C22.0976 2.68342 22.0976 3.31658 21.7071 3.70711Z"
                          fill="#fff"
                        />
                        <path
                          d="M9 20C9 20.5523 9.44772 21 10 21C10.5523 21 11 20.5523 11 20V14.0007C11 13.9997 11 13.998 11 13.997C10.9992 13.7231 10.8883 13.4752 10.7092 13.295C10.7078 13.2936 10.7064 13.2922 10.705 13.2908C10.6096 13.196 10.4999 13.1243 10.3828 13.0759C10.2657 13.0273 10.1375 13.0004 10.003 13C10.002 13 10.001 13 10 13H4C3.44772 13 3 13.4477 3 14C3 14.5523 3.44772 15 4 15H7.58579L2.29289 20.2929C1.90237 20.6834 1.90237 21.3166 2.29289 21.7071C2.68342 22.0976 3.31658 22.0976 3.70711 21.7071L9 16.4142V20Z"
                          fill="#fff"
                        />
                      </svg>
                      {/* <svg width="24px" height="24px" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
                          <g>
                            <path d="M30,60H6A6,6,0,0,0,6,72H24V90a6,6,0,0,0,12,0V66A5.9966,5.9966,0,0,0,30,60Z"/>
                            <path d="M90,60H66a5.9966,5.9966,0,0,0-6,6V90a6,6,0,0,0,12,0V72H90a6,6,0,0,0,0-12Z"/>
                            <path d="M66,36H90a6,6,0,0,0,0-12H72V6A6,6,0,0,0,60,6V30A5.9966,5.9966,0,0,0,66,36Z"/>
                            <path d="M30,0a5.9966,5.9966,0,0,0-6,6V24H6A6,6,0,0,0,6,36H30a5.9966,5.9966,0,0,0,6-6V6A5.9966,5.9966,0,0,0,30,0Z"/>
                          </g>
                        </svg> */}
                    </>
                  ) : (
                    <>
                      {/* <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4 1.5C2.61929 1.5 1.5 2.61929 1.5 4V8.5C1.5 9.05228 1.94772 9.5 2.5 9.5H3.5C4.05228 9.5 4.5 9.05228 4.5 8.5V4.5H8.5C9.05228 4.5 9.5 4.05228 9.5 3.5V2.5C9.5 1.94772 9.05228 1.5 8.5 1.5H4Z" fill="inherit"/>
                          <path d="M20 1.5C21.3807 1.5 22.5 2.61929 22.5 4V8.5C22.5 9.05228 22.0523 9.5 21.5 9.5H20.5C19.9477 9.5 19.5 9.05228 19.5 8.5V4.5H15.5C14.9477 4.5 14.5 4.05228 14.5 3.5V2.5C14.5 1.94772 14.9477 1.5 15.5 1.5H20Z" fill="inherit"/>
                          <path d="M20 22.5C21.3807 22.5 22.5 21.3807 22.5 20V15.5C22.5 14.9477 22.0523 14.5 21.5 14.5H20.5C19.9477 14.5 19.5 14.9477 19.5 15.5V19.5H15.5C14.9477 19.5 14.5 19.9477 14.5 20.5V21.5C14.5 22.0523 14.9477 22.5 15.5 22.5H20Z" fill="inherit"/>
                          <path d="M1.5 20C1.5 21.3807 2.61929 22.5 4 22.5H8.5C9.05228 22.5 9.5 22.0523 9.5 21.5V20.5C9.5 19.9477 9.05228 19.5 8.5 19.5H4.5V15.5C4.5 14.9477 4.05228 14.5 3.5 14.5H2.5C1.94772 14.5 1.5 14.9477 1.5 15.5V20Z" fill="inherit"/>
                        </svg> */}
                      <svg
                        width="28px"
                        height="28px"
                        viewBox="0 0 24 24"
                        fill="ffffff"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.7092 2.29502C21.8041 2.3904 21.8757 2.50014 21.9241 2.61722C21.9727 2.73425 21.9996 2.8625 22 2.997L22 3V9C22 9.55228 21.5523 10 21 10C20.4477 10 20 9.55228 20 9V5.41421L14.7071 10.7071C14.3166 11.0976 13.6834 11.0976 13.2929 10.7071C12.9024 10.3166 12.9024 9.68342 13.2929 9.29289L18.5858 4H15C14.4477 4 14 3.55228 14 3C14 2.44772 14.4477 2 15 2H20.9998C21.2749 2 21.5242 2.11106 21.705 2.29078L21.7092 2.29502Z"
                          fill="#ffffff"
                        />
                        <path
                          d="M10.7071 14.7071L5.41421 20H9C9.55228 20 10 20.4477 10 21C10 21.5523 9.55228 22 9 22H3.00069L2.997 22C2.74301 21.9992 2.48924 21.9023 2.29502 21.7092L2.29078 21.705C2.19595 21.6096 2.12432 21.4999 2.07588 21.3828C2.02699 21.2649 2 21.1356 2 21V15C2 14.4477 2.44772 14 3 14C3.55228 14 4 14.4477 4 15V18.5858L9.29289 13.2929C9.68342 12.9024 10.3166 12.9024 10.7071 13.2929C11.0976 13.6834 11.0976 14.3166 10.7071 14.7071Z"
                          fill="#ffffff"
                        />
                      </svg>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(VideoContainer);
