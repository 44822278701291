import React, { useEffect, useState, useRef } from "react";
import { useTranslation, Trans } from 'react-i18next';

import { ReactComponent as HelpLogoSVG } from "@material-design-icons/svg/round/help.svg";
import { ReactComponent as HelpInfoLogoSVG } from "@material-design-icons/svg/round/info.svg";


export function HelpLogo({containerProps, containerStyle, props}) {
  return (
    <div style={{display:'inline', position:'relative', ...containerStyle}}  {...containerProps}  >
      <HelpLogoSVG fill='var(--purple-help)' {...props} />
    </div>
  );
}

export function HelpInfoLogo({containerProps, containerStyle, props}) {
  return (
    <div style={{display:'inline', position:'relative', ...containerStyle}}  {...containerProps}  >
      <HelpInfoLogoSVG fill='var(--purple-help)' {...props} />
    </div>
  );
}




export const HelperHelpDocsIcon = ({}) => {
  const { t } = useTranslation();
  return (
    <>
    <style>
    {`
      .helper-icon {
          display: inline-block;
          cursor: pointer;
          font-size: 1rem;
          color: white;
          /*background-color: var(--gray-text); 333333 or 666666
          background-color: var(--gray-dark);
          background-color: var(--gray-text); 333333 or 666666 */
          background-color: #666666;
          border: 3px solid white;
          position: relative;
          width: 3rem;
          border-radius: 1rem 0 0 1rem;
          padding-bottom: .3rem;
          border-right: none;
      }

          .helper-icon-text {
        position: relative;
        font-family: 'Nunito', sans-serif;
        font-size: 1.2rem;
        top: .4rem;
        font-weight: bold;
        text-orientation: upright;
        writing-mode: vertical-rl;
        letter-spacing: 0.5rem;
        margin-top: .4rem;
        margin-bottom: .4rem;
        left: .5rem;        
        }
      `}
    </style>
    <div className="helper-icon">
      <div className='helper-icon-text'>
      {'? '+ t('icons.help')}
      </div>
    </div>
    </>
  )
}

